import React, { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Filler, Legend, Tooltip } from "chart.js";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";
import moment from "moment";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import dummyImg from "../../../../src/assets/images/graphCall.png";
import graphChart from "../../../../src/assets/images/graphChart.png";
import donutImg from "../../../../src/assets/images/donutChart.png"
import { color } from "echarts";
// import { Tooltip } from "chart.js";
import { Tooltip as ReactTooltip } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

// Register the required components with Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler, Legend, Tooltip);


const OverallCallPieChart = ({ piechartsummaryData, dataColors, piechartData }) => {
  const navigate = useNavigate();

  var chartDonutupdatingColors = getChartColorsArray(dataColors);
  // const [loading, setLoading] = useState(true);
  const tableData = useMemo(() => {
    return piechartsummaryData?.map(item => ({
      value: item.totalCalls,
    })) || [];
  }, [piechartsummaryData]);

  const handleNavigation = () => {
    navigate("/call-report");
  };


  let response = [];
  if (Array.isArray(piechartsummaryData)) {
    response = [...piechartsummaryData];
  }

  response.push({
    _id: "TOTAL CALL",
    totalCalls: piechartData?.totalCall ?? 0,
  });

  const labelData = useMemo(() => {
    return piechartsummaryData?.map(item => item._id) || [];
  }, [piechartsummaryData]);


  const series = tableData.map(item => item.value);

  const options = {
    labels: labelData,
    chart: {
      height: 280,
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
    },

    colors: chartDonutupdatingColors,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '16px',
              fontWeight: 600,
              color: '#333',
              offsetY: -10,
            },
            total: {
              showAlways: true,
              show: true,
              fontSize: '14px',
              color: '#333333',
            },

            value: {
              show: true,
              fontSize: '13px',
              fontWeight: 600,
              color: '#333333',
              offsetY: 1,
              formatter: (val) => val.toLocaleString(),
            },
          },
        },
      },
    }
  };

  return (
    <React.Fragment>
      <div className="col-md-3 col-sm-12 col-xs-12" >
        <div className="card card-height-100 " >
          <div className="d-flex justify-content-end  p-2">
            <button onClick={handleNavigation} className="btn btn-sm border border-rounded shadow sn-dashboard-count-btn-view" >View reports</button>
          </div>
          {piechartsummaryData?.length > 0 ? (
            <CardBody>
              <>
                <div className="card-body" >
                  <div
                    id="portfolio_donut_charts"
                    className="apex-charts"
                    dir="ltr"
                  >
                    <ReactApexChart
                      dir="ltr"
                      className="apex-charts"
                      series={series}
                      options={options}
                      type="donut"
                      height={267.7}
                    />

                  </div>
                  < ul className="list-group list-group-flush border-dashed mb-0 mt-3 pt-2" >
                    {
                      response.sort((a, b) => a?._id.localeCompare(b?._id))?.map((item, index) => {
                        return (
                          <div key={index} >
                            <li className="list-group-item px-0" >
                              <div className="d-flex align-items-center" >
                                <div className="flex-grow-1 ms-2" >
                                  <h6
                                    style={
                                      {
                                        paddingRight: "20px",
                                        fontSize: "11px",
                                      }
                                    }
                                    className="mb-0"
                                  >
                                    {item?._id}
                                  </h6>
                                </div>
                                < div className="flex-shrink-0 text-end" >
                                  <h4
                                    className="mb-0 fw-bold"
                                    style={{
                                      paddingRight: "20px",
                                      fontSize: "11px",
                                    }
                                    }
                                  >
                                    {item?.totalCalls}
                                  </h4>
                                </div>
                              </div>
                            </li>
                          </div>
                        );
                      })
                    }
                  </ul>
                </div>
              </>
            </CardBody>
          ) : (
            <>
              <div className="sn-dashboard-blur-img" >
                <img src={donutImg} alt="" style={{ filter: "blur(2px)" }} className="img-filter-no-data" />
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};


const OverallCallsVolume = ({ dataColors, graphData, callGraphData, formattedDates }) => {
  var lineChartColor = getChartColorsArray(dataColors);

  const [tooltipOpenOverall, setTooltipOverall] = useState(false);
  const toggleOverall = () => setTooltipOverall(!tooltipOpenOverall);

  const graphDate = graphData.map((item) => moment(item?._id?.date).format("MMM DD"))
  const strDate = formattedDates?.startDate
  const enndDate = formattedDates?.endDate
  const date1 = new Date(strDate);
  const date2 = new Date(enndDate);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  const service = {
    labels: diffDays > 0
      ? graphDate.sort((a, b) => new Date(a) - new Date(b))
      : callGraphData?.map((item) => item.workingTime),
    datasets: [
      {
        label: "Incoming Calls",
        fill: true,
        lineTension: 0.5,
        backgroundColor: lineChartColor[0],
        borderColor: lineChartColor[1],
        pointBorderColor: lineChartColor[1],
        pointBackgroundColor: "#67b173",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineChartColor[1],
        pointHoverBorderColor: "#67b173",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: diffDays > 0 ? graphData?.map((item) => item.totallIncomingCall) : callGraphData?.map((item) => item.totallIncomingCall),
      },
      {
        label: "Outgoing Calls",
        fill: true,
        lineTension: 0.5,
        backgroundColor: lineChartColor[2],
        borderColor: lineChartColor[3],
        pointBorderColor: lineChartColor[3],
        pointBackgroundColor: "#80adfd",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineChartColor[3],
        pointHoverBorderColor: "#80adfd",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: diffDays > 0 ? graphData?.map((item) => item.totallOutgoingCall) : callGraphData?.map((item) => item.totallOutgoingCall),
      },
      {
        label: "Rejected Calls",
        fill: true,
        lineTension: 0.5,
        backgroundColor: lineChartColor[6],
        borderColor: lineChartColor[7],
        pointBorderColor: lineChartColor[7],
        pointBackgroundColor: "#eca8f7",  // Added missing #
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineChartColor[7],
        pointHoverBorderColor: "#eca8f7",  // Added missing #
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: diffDays > 0 ? graphData?.map((item) => item.totalRejectedCall) : callGraphData?.map((item) => item.totalRejectedCall),
      },
      {
        label: "Missed Calls",
        fill: true,
        lineTension: 0.5,
        backgroundColor: lineChartColor[8],
        borderColor: lineChartColor[9],
        pointBorderColor: lineChartColor[9],
        pointBackgroundColor: "#f17171",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: lineChartColor[9],
        pointHoverBorderColor: "#f17171",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: diffDays > 0 ? graphData?.map((item) => item.totallMissedCall) : callGraphData?.map((item) => item.totallMissedCall),
      },
    ],
  };

  const option = {
    scales: {
      x: {
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
      y: {
        ticks: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            family: "Poppins",
          },
        },
      },
      tooltip: {
        enabled: true, // Enable tooltips
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y; // Display the y-value in the tooltip
            }
            return label;
          },
        },
      },
    },
    interaction: {
      mode: 'index',  // Ensures the tooltip shows when hovering near points
      intersect: false,
    },
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl={12} className="sm sn-over-call-graph-main-home" >
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Overall Calls Volumes
                <span className="ri-information-line sn-dashboard-tble-toll" data-bs-toggle="tooltip" data-bs-placement="top"
                  id="DisabledAutoHideOverall"></span>
                <div>

                  <ReactTooltip
                    placement="top"
                    isOpen={tooltipOpenOverall}
                    autohide={false}
                    target="DisabledAutoHideOverall"
                    toggle={toggleOverall}
                    style={{ maxWidth: '300px' }}
                  >
                    Overall Call Volume shows the total flow of incoming, outgoing, answered, rejected, and missed calls within the selected
                    date range, giving you a comprehensive view of call activity over time.
                  </ReactTooltip>
                </div>

              </h4>
            </CardHeader>
            {
              graphData?.length > 0 ? (
                <CardBody>
                  <Line style={{ width: '100%' }} width={723} height={200} data={service} options={option} />
                </CardBody>
              ) : (
                <div className="sn-dashboard-blur-img" >
                  <img src={graphChart} alt="" style={{ filter: "blur(2px)", width: '100%' }} />
                </div>
              )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );

};


const MostActiveHourByCalls = ({ graphData }) => {
  const [tooltipOpenHour, setTooltipHour] = useState(false);
  const toggleHour = () => setTooltipHour(!tooltipOpenHour);

  const series = [
    {
      name: "Outgoing Call",
      data: graphData?.map((item) => item.totallOutgoingCall),
    },
    {
      name: "Incoming Call",
      data: graphData?.map((item) => item.totallIncomingCall),
    },
    {
      name: "Missed Call",
      data: graphData?.map((item) => item.totallMissedCall),
    },
    {
      name: "Rejected Call",
      data: graphData?.map((item) => item.totalRejectedCall),
    },
    {
      name: "Not Picked Call",
      data: graphData?.map((item) => item.totalNotPickedUpByClient),
    },
  ];

  const option = {
    chart: {
      type: "bar",
      stacked: true,
      height: 380,
    },

    plotOptions: {
      bar: {
        barHeight: "100%",
        horizontal: true,
        columnWidth: "45%",
      },
    },

    grid: {
      show: false,
    },

    dataLabels: {
      enabled: false,
    },
    // colors: ["#80adfd", "#9ce9a7", "#e7a6a6", "#eca8f7", "#f5d17f"],
    colors: ["#80adfd", "#67b173", "#f17171", "#eca8f7", "#f5d17f"],

    stroke: {
      width: 2,
      colors: ["#fb6c6d00"],
    },
    plotOptions: {
      bar: {
        barHeight: "22px",
        horizontal: true,
        columnWidth: "5%",
      },
    },

    title: {
      text: "",
    },

    xaxis: {
      categories: graphData?.map((item) => item.workingTime),

      labels: {
        style: {
          color: "#fb6c6d",
          fontSize: "12px",
        },
        formatter: (val) => {
          if (val >= 100000) {
            return (val / 100000).toFixed(1) + "L";
          } else if (val >= 1000) {
            return (val / 1000).toFixed(1) + "K";
          } else {
            return val;
          }
        },
      },
    },

    tooltip: {
      // y: {
      //     formatter: function (val) {
      //         return val + "K"
      //     }
      // }
    },

    fill: {
      opacity: 1,
    },

    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 90,
    },
  };


  return (
    <React.Fragment>
      <Row>
        <Col className="col col-sm col-md col-lg col-xl col-xxl" >
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Most Active Hour By Calls
                <span data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideHour" className="ri-information-line sn-dashboard-tble-toll"></span>
                <div>
                  <ReactTooltip
                    placement="top"
                    isOpen={tooltipOpenHour}
                    autohide={false}
                    target="DisabledAutoHideHour"
                    toggle={toggleHour}
                    style={{ maxWidth: '300px' }}
                  >
                    Most Active Hour by Calls displays the busiest hours of the day, showing the distribution of incoming, outgoing, missed, rejected, and not picked calls by time.
                    This helps identify peak call activity periods.
                  </ReactTooltip>
                </div>
              </h4>
            </CardHeader>
            {
              graphData?.length > 0 ? (
                <CardBody>
                  <ReactApexChart
                    dir="ltr"
                    className="apex-charts"
                    options={option}
                    series={series}
                    type="bar"
                    height={400}
                  />
                </CardBody>
              ) : (
                <div className="sn-dashboard-blur-img" >
                  <img src={dummyImg} alt="" style={{ filter: "blur(2px)", width: '100%' }} />
                </div>
              )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};




const ApexChart = ({ dataColors, graphData, callGraphData, formattedDates }) => {
  const [tooltipOpenOverall, setTooltipOverall] = useState(false);
  const toggleOverall = () => setTooltipOverall(!tooltipOpenOverall);

  const graphDate = graphData.map((item) => moment(item?._id?.date).format("MMM DD"))
  const strDate = formattedDates?.startDate
  const enndDate = formattedDates?.endDate
  const date1 = new Date(strDate);
  const date2 = new Date(enndDate);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));


  const series = [
    {
      name: "Incoming Calls",
      data: diffDays > 0 ? graphData?.map((item) => item.totallIncomingCall) : callGraphData?.map((item) => item.totallIncomingCall),
    },
    {
      name: "Outgoing Calls",
      data: diffDays > 0 ? graphData?.map((item) => item.totallOutgoingCall) : callGraphData?.map((item) => item.totallOutgoingCall),
    },
    {
      name: "Rejected Calls",
      data: diffDays > 0 ? graphData?.map((item) => item.totalRejectedCall) : callGraphData?.map((item) => item.totalRejectedCall),
    },
    {
      name: "Missed Calls",
      data: diffDays > 0 ? graphData?.map((item) => item.totallMissedCall) : callGraphData?.map((item) => item.totallMissedCall),
    },

  ]


  const option = {
    chart: {
      height: 350,
      type: 'area'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center'
    },
    xaxis: {
      type: 'time',
      categories: diffDays > 0
        ? graphDate.sort((a, b) => new Date(a) - new Date(b))
        : callGraphData?.map((item) => item.workingTime),
    },
    tooltip: {
      x: {
        format: 'HH:mm'
      },
    }
  }


  return (
    <React.Fragment>
      <Row>
        <Col xl={12} className="sm sn-over-call-graph-main-home" >
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Overall Calls Volumes
                <span className="ri-information-line sn-dashboard-tble-toll" data-bs-toggle="tooltip" data-bs-placement="top"
                  id="DisabledAutoHideOverall"></span>
                <div>

                  <ReactTooltip
                    placement="top"
                    isOpen={tooltipOpenOverall}
                    autohide={false}
                    target="DisabledAutoHideOverall"
                    toggle={toggleOverall}
                    style={{ maxWidth: '300px' }}
                  >
                    Overall Call Volume shows the total flow of incoming, outgoing, answered, rejected, and missed calls within the selected
                    date range, giving you a comprehensive view of call activity over time.
                  </ReactTooltip>
                </div>

              </h4>
            </CardHeader>
            {
              graphData?.length > 0 ? (
                <CardBody>
                  <ReactApexChart options={option} series={series} type="area" height={350} />
                </CardBody>
              ) : (
                <div className="sn-dashboard-blur-img" >
                  <img src={graphChart} alt="" style={{ filter: "blur(2px)", width: '100%' }} />
                </div>
              )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>

  );
};




export { OverallCallPieChart, OverallCallsVolume, MostActiveHourByCalls, ApexChart };
