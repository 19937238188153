import React from 'react';
import CallPerformance from './CallPerformance';

const formatDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;
  return `${hours}h ${minutes}m ${seconds}s`;
};

const columnsTopCallers = [
  {
    name: <span className="font-weight-bold fs-13">S No</span>,
    selector: (row, index) => index + 1,
    sortable: false,
    width: '80px',
  },
  {
    name: <span className="font-weight-bold fs-13">Employee Name</span>,
    selector: row => row.fullName,
    cell: row => (
      <div className="d-flex align-items-center">
        {row.profileImageUrl ? (
          <img
            src={row.profileImageUrl}
            alt="Profile"
            style={{
              width: "35px",
              height: "35px",
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          />
        ) : (
          <div
            className="rounded-circle d-flex justify-content-center align-items-center"
            style={{
              width: "35px",
              height: "35px",
              background: "DodgerBlue",
              marginRight: "10px",
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            {row.fullName ? row.fullName.charAt(0).toUpperCase() : '?'}
          </div>
        )}
        <span>{row.fullName || 'Unknown'}</span>
      </div>
    ),
    sortable: true,
  },
  {
    name: <span className="font-weight-bold fs-13">Call Count</span>,
    selector: row => row.callCount,
    sortable: true,
  },
  {
    name: <span className="font-weight-bold fs-13">Total Duration</span>,
    selector: row => row.totalDuration,
    cell: row => <span>{formatDuration(row.totalDuration)}</span>,
    sortable: true,
  },
];

const columnsTopDialers = [
  {
    name: <span className="font-weight-bold fs-13">S No</span>,
    selector: (row, index) => index + 1,
    sortable: false,
    width: '80px',
  },
  {
    name: <span className="font-weight-bold fs-13">Employee Name</span>,
    selector: row => row.fullName,
    cell: row => (
      <div className="d-flex align-items-center">
        {row.profileImageUrl ? (
          <img
            src={row.profileImageUrl}
            alt="Profile"
            style={{
              width: "35px",
              height: "35px",
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          />
        ) : (
          <div
            className="rounded-circle d-flex justify-content-center align-items-center"
            style={{
              width: "35px",
              height: "35px",
              background: "DodgerBlue",
              marginRight: "10px",
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            {row.fullName ? row.fullName.charAt(0).toUpperCase() : '?'}
          </div>
        )}
        <span>{row.fullName || 'Unknown'}</span>
      </div>
    ),
    sortable: true,
  },
  {
    name: <span className="font-weight-bold fs-13">Total Outgoing Calls</span>,
    selector: row => row.totalOutgoing || 0, // Fallback to 0 if undefined
    sortable: true,
  },
  {
    name: <span className="font-weight-bold fs-13">Total Duration</span>,
    selector: row => row.totalDuration || 0, // Fallback to 0 if undefined
    cell: row => <span>{formatDuration(row.totalDuration || 0)}</span>,
    sortable: true,
  },
];

const columnsTopConnected = [
  {
    name: <span className="font-weight-bold fs-13">S No</span>,
    selector: (row, index) => index + 1,
    sortable: false,
    width: '80px',
  },
  {
    name: <span className="font-weight-bold fs-13">Employee Name</span>,
    selector: row => row.fullName,
    cell: row => (
      <div className="d-flex align-items-center">
        {row.profileImageUrl ? (
          <img
            src={row.profileImageUrl}
            alt="Profile"
            style={{
              width: "35px",
              height: "35px",
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          />
        ) : (
          <div
            className="rounded-circle d-flex justify-content-center align-items-center"
            style={{
              width: "35px",
              height: "35px",
              background: "DodgerBlue",
              marginRight: "10px",
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            {row.fullName ? row.fullName.charAt(0).toUpperCase() : '?'}
          </div>
        )}
        <span>{row.fullName || 'Unknown'}</span>
      </div>
    ),
    sortable: true,
  },
  {
    name: <span className="font-weight-bold fs-13">Total Answered Calls</span>,
    selector: row => row.totalAnswered,
    sortable: true,
  },
  {
    name: <span className="font-weight-bold fs-13">Total Duration</span>,
    selector: row => row.totalDuration,
    cell: row => <span>{formatDuration(row.totalDuration)}</span>,
    sortable: true,
  },
];

const columnsTopDuration = [
  {
    name: <span className="font-weight-bold fs-13">S No</span>,
    selector: (row, index) => index + 1,
    sortable: false,
    width: '80px',
  },
  {
    name: <span className="font-weight-bold fs-13">Employee Name</span>,
    selector: row => row.fullName,
    cell: row => (
      <div className="d-flex align-items-center">
        {row.profileImageUrl ? (
          <img
            src={row.profileImageUrl}
            alt="Profile"
            style={{
              width: "35px",
              height: "35px",
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          />
        ) : (
          <div
            className="rounded-circle d-flex justify-content-center align-items-center"
            style={{
              width: "35px",
              height: "35px",
              background: "DodgerBlue",
              marginRight: "10px",
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            {row.fullName ? row.fullName.charAt(0).toUpperCase() : '?'}
          </div>
        )}
        <span>{row.fullName || 'Unknown'}</span>
      </div>
    ),
    sortable: true,
  },
  {
    name: <span className="font-weight-bold fs-13">Total Call Count</span>,
    selector: row => row.callCount || 0, // Fallback to 0 if undefined
    sortable: true,
  },
  {
    name: <span className="font-weight-bold fs-13">Total Duration</span>,
    selector: row => row.totalDuration || 0, // Fallback to 0 if undefined
    cell: row => <span>{formatDuration(row.totalDuration || 0)}</span>,
    sortable: true,
  },
];

export const TopCallersReport = () => {
  return (
    <CallPerformance title="Top Caller" columns={columnsTopCallers} sortKey="callCount" headerName = 'Employee with the highest total calls (incoming and outgoing) today.' />
  );
};

export const TopDialersReport = () => {
  return (
    <CallPerformance title="Top Dialer" columns={columnsTopDialers} sortKey="totalOutgoing" headerName = 'Employee with the most outgoing calls made today.'/>
  );
};

export const TopConnectedReport = () => {
  return (
    <CallPerformance title="Top Answered" columns={columnsTopConnected} sortKey="totalAnswered" headerName = 'Employee who received and answered the highest number of calls today.' />
  );
};

export const TopDurationReport = () => {
  return (
    <CallPerformance title="Highest Call Duration" columns={columnsTopDuration} sortKey="totalDuration" headerName = 'Employee with the longest cumulative call time today across all calls.'/>
  );
};