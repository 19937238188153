const ConvertTimestampToTime = (timestamp) => {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours.toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds} ${ampm}`;
}

const formatDuration = (duration) => {
    const seconds = Math.floor(duration % 60);
    const minutes = Math.floor((duration / 60) % 60);
    const hours = Math.floor(duration / 3600);
    return [hours, minutes, seconds].map(v => String(v).padStart(2, '0')).join(':');
};




export { ConvertTimestampToTime, formatDuration}