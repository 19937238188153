import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  RESET_LOGIN_FLAG
} from "./actionTypes";

const initialState = {
  user: {},
  isAuthenticated: !!localStorage.getItem('token'),
  token: localStorage.getItem('token'),
  loading: false,
  loginType: {},
  permission: [],
  error: null,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        user: action.payload.user,
        loginType: action?.payload?.loginType,
        loading: true,
        error: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        isAuthenticated: true,
        error: null,
        loading: false,
        loginType: action?.payload?.loginType,
        permission: action.payload?.permission
      };
    case LOGOUT_USER:
    case LOGOUT_USER_SUCCESS:
      localStorage.removeItem('token');
      localStorage.removeItem('logintype');
      localStorage.removeItem('SuperAdminlogintype');
      localStorage.removeItem('loginPublicType')
      localStorage.removeItem('permission')
      localStorage.removeItem('selectedPermissions')
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        user: null,
        error: null,
        loading: false,
        loginType: null
      };
    case API_ERROR:
      return {
        ...state,
        errorMsg: action.payload.data,
        loading: true,
        error: true,
        isUserLogout: false,
      };
    case RESET_LOGIN_FLAG:
      return {
        ...state,
        errorMsg: null,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export default login;
