import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Input,
    Label,
    Button,
    Form,
    Alert,
    Spinner,
    UncontrolledAlert,
    FormFeedback,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser, resetLoginFlag } from "../../store/actions";
import withRouter from "../../Components/Common/withRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfile } from "../../Components/Hooks/UserHooks";
import classnames from "classnames";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
// import { useLoginType } from "../../Components/Common/LoginTypeContext";

import logoheader from "../../assets/images/logo-main-white.png";
import Footer from '../../Layouts/Footer';

const SuperAdmin = (props) => {
    // const { setLoginType } = useLoginType();
    const { token } = useProfile();
    const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, errorMsg, loading, error } = useSelector((state) => ({
        user: state.Account.user,
        errorMsg: state.Login.errorMsg,
        loading: state.Login.loading,
        error: state.Login.error,
    }));

    const [success, setSuccess] = useState(false);
    const [userLogin, setUserLogin] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);

    // Custom Tabs Bordered
    const [customActiveTab, setcustomActiveTab] = useState(() => {
        const savedTab = localStorage.getItem("loginPublicType");
        return savedTab ? JSON.parse(savedTab) : "organization";
    });

    useEffect(() => {
        if (token) {
            navigate("/admin-dashboard");
        }
    }, [token, navigate]);

    useEffect(() => {
        if (user && user) {
            setUserLogin({
                email: user?.user?.email,
                password: user?.user?.confirm_password,
            });
        }
    }, [user]);

    const { handleSubmit, errors, touched, getFieldProps } = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: userLogin.email || "",
            password: userLogin.password || "",
            // terms: false
        },

        validationSchema: Yup.object({
            email: Yup.string()
                .email("Please enter a valid email address")
                .matches(emailRules, { message: "  Invalid email" })
                .required("Email address is required"),
            password: Yup.string().required("Password is required"),
            // terms: Yup.boolean().isTrue('Please accept Terms and Conditions'),
        }),
        onSubmit: (values) => {
            const customActiveTab = "SuperAdmin"
            localStorage.setItem("SuperAdminlogintype", JSON.stringify(customActiveTab));
            dispatch(loginUser(values, customActiveTab, props.router.navigate));
        },
    });

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                dispatch(resetLoginFlag());
            }, 3000);
        }
    }, [dispatch, error]);


    // const handleClick = () => {
    //     navigate('/forgot-password', { state: "organization" })
    // }


    return (
        <React.Fragment>
            <ToastContainer />
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo"></Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className="sn-login-header-logo">
                                <div className="sn-admin-login-logo">
                                    <img src={logoheader} alt="Logo" />
                                </div>
                            </div>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody>
                                        <Nav
                                            tabs
                                            className="nav nav-tabs nav-tabs-custom  nav-justified mb-0"
                                        >
                                            <NavItem>
                                                <NavLink
                                                style={{lineHeight:'30px',fontSize:'18px'}}
                                                    className={classnames({
                                                        active: customActiveTab,
                                                    })}>
                                                   Admin Login
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent className="text-muted sn-login-ninja-page-form">
                                            <div className="mt-4">
                                                <div className="p-4">
                                                    <div className="text-center mt-2">
                                                        <h3 className="text-dark">Sign In</h3>
                                                    </div>
                                                    {error && error ? (
                                                        <Alert color="danger">
                                                            {" "}
                                                            Email or Password Incorrect
                                                        </Alert>
                                                    ) : null}

                                                    {success && success ? (
                                                        <UncontrolledAlert
                                                            color="success"
                                                            className="alert-border-left"
                                                        >
                                                            <i className="ri-notification-off-line me-3 align-middle fs-16"></i>
                                                            <strong>Successfully login</strong>
                                                        </UncontrolledAlert>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div className="p-2 mt-4">
                                                        <Form onSubmit={handleSubmit} action="#">
                                                            <div className="mb-3">
                                                                <Label htmlFor="email" className="form-label">
                                                                    Email<span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    data-tooltip-id="my-tooltip-login-1"
                                                                    type="email"
                                                                    placeholder="Enter email address"
                                                                    {...getFieldProps("email")}
                                                                    invalid={touched.email && errors.email}
                                                                    className="form-control"
                                                                />
                                                                {touched.email && errors.email && (
                                                                    <FormFeedback type="invalid"></FormFeedback>
                                                                )}
                                                                <ReactTooltip
                                                                    id="my-tooltip-login-1"
                                                                    place="top"
                                                                    className="custom-tooltip-email"
                                                                >
                                                                    {touched.email && errors.email
                                                                        ? errors.email
                                                                        : ""}
                                                                </ReactTooltip>
                                                            </div>

                                                            <div className="mb-3">
                                                                <div className="float-end">
                                                                    <Button className="btn-forgot-password">
                                                                        <Link

                                                                            className="fw-semibold text-danger text-decoration-underline"
                                                                        >
                                                                            Forgot password
                                                                        </Link>
                                                                    </Button>
                                                                </div>
                                                                <Label
                                                                    className="form-label"
                                                                    htmlFor="password-input"
                                                                >
                                                                    Password
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                                    <Input
                                                                        name="password"
                                                                        data-tooltip-id="my-tooltip-login-2"
                                                                        type={passwordShow ? "text" : "password"}
                                                                        placeholder="Enter Password"
                                                                        {...getFieldProps("password")}
                                                                        invalid={
                                                                            touched.password && errors.password
                                                                        }
                                                                        className="form-control"
                                                                    />
                                                                    {touched.password && errors.password && (
                                                                        <FormFeedback type="invalid"></FormFeedback>
                                                                    )}
                                                                    <ReactTooltip
                                                                        id="my-tooltip-login-2"
                                                                        place="top"
                                                                        className="custom-tooltip-email"
                                                                    >
                                                                        {touched.password && errors.password
                                                                            ? errors.password
                                                                            : ""}
                                                                    </ReactTooltip>
                                                                    <button
                                                                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                                                        type="button"
                                                                        id="password-addon"
                                                                        onClick={() =>
                                                                            setPasswordShow(!passwordShow)
                                                                        }
                                                                    >
                                                                        <i className="ri-eye-fill align-middle"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {/* <div className="mb-4">
                                                                    <div className="col-sm" style={{ marginLeft: '8px' }}>
                                                                        <input id='leadTermCheck' type="checkbox"
                                                                            {...getFieldProps('terms')}
                                                                        />&nbsp;
                                                                        <label htmlFor="Business" className="custom-control-label dt-cursor"> <Label for='leadTermCheck'>I accept all terms and conditions.</Label></label><br />
                                                                        {(touched.terms && errors.terms) && <span className="text-danger error">{errors.terms}</span>}
                                                                    </div>
                                                                </div> */}
                                                            <div className="mt-4">
                                                                <Button
                                                                    color="primary"
                                                                    disabled={
                                                                        error ? null : loading ? true : false
                                                                    }
                                                                    className="btn btn-danger w-100"
                                                                    type="submit"
                                                                >
                                                                    {error ? null : loading ? (
                                                                        <Spinner size="sm" className="me-2">
                                                                            {" "}
                                                                            Loading...{" "}
                                                                        </Spinner>
                                                                    ) : null}
                                                                    Sign In
                                                                </Button>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="mt-4 text-center">
                                                <p className="mb-0">
                                                    Don't have an account ?{" "}
                                                    <Link
                                                        to="/register"
                                                        className="fw-semibold text-danger text-decoration-underline"
                                                    >
                                                        {" "}
                                                        Signup{" "}
                                                    </Link>{" "}
                                                </p>
                                            </div> */}
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="sn-footer-botom-page">
                    <Footer />
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(SuperAdmin);
