import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { DELETE_ARCHIVES, GET_ARCHIVES } from "../../helpers/url_helper";
import axios from "axios";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import CommanDataTable from "../../Components/Common/DataTable";
import { DatePickerWithoutTimes } from "../../Components/Common/Flatpicker";
import TeamSelect from "../../Components/Common/TeamSelect";
import EmployeeSelect from "../../Components/Common/EmployeeSelect";
import { formatDate } from "../../Components/Common/FormDateTime";
import { APIClient } from "../../helpers/api_helper";
import { getObjectWiseDefaultEmployeeIds, getPreviousDate, setCurrentDate } from "../../Components/Common/DefaultDateSet";
import Toast from "../../Components/Common/Toast";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import { toCamelCase } from "../../Components/Common/ToCamelCase";
import { useProfileData } from "../../Components/Hooks/UserHooks";
import { useNavigate } from "react-router-dom";
import SubExpired from "../../Components/Common/subExpired";

const api = new APIClient();

const CallArchivesReport = () => {
    document.title = "Archives Recordings";
    const [isLoading, setIsLoading] = useState(true);
    const { profileData } = useProfileData()
    const navigate = useNavigate();
    const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);
    const permissionType = JSON.parse(localStorage.getItem("permission"));
    const loginType = JSON.parse(localStorage.getItem("logintype"))
    const GetArchivePermission = permissionType?.includes("GET_ARCHIVE");

    const [EmployeeData, setEmployeeData] = useState([])
    const previewsDate = getPreviousDate()
    const currentDate = setCurrentDate()

    //search filed use in
    const [searchText, setSearchText] = useState('');

    //date picker state
    const [selectedFromDate, setSelectedFromDate] = useState(previewsDate);
    const [selectedToDate, setSelectedToDate] = useState(currentDate);

    // employee select 
    const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);

    const [remainingDays, setRemainingDays] = useState(null);

    //select team state
    const [teamIndu, setTeamIndu] = useState([])

    // Pagination settings
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [archivesData, setArchivesData] = useState()

    const [employeesId, setEmployeeId] = useState();
    // flatPicker Date
    const formattedStartDate = formatDate(selectedFromDate);
    const formattedEndDate = formatDate(selectedToDate);


    // From Date
    const handleFromDateChange = (dateStr) => {
        setSelectedFromDate(dateStr)
    }

    // To date
    const handleToDateChange = (dateStr) => {
        setSelectedToDate(dateStr)
    }

    // search field 
    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    // table column
    const column = [
        {
            name: <span className='font-weight-bold fs-13'>Employee</span>,
            selector: row => toCamelCase(row.employeeName),
            cell: row => <span style={{ textTransform: 'capitalize' }}>{row.employeeName}</span>,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Created At</span>,
            selector: row => moment(row.createdAt).format('DD MMM YYYY hh:mm A'),
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Archive Size</span>,
            selector: row => row.archiveSize,
        },
        {
            name: <span className='font-weight-bold fs-13'>Archive Status</span>,
            selector: (cell) => {
                switch (cell.archiveStatus) {
                    case "DELETED":
                        return <span className="badge sn-delete-text-tble"> {cell.archiveStatus} </span>;
                    case "PENDING":
                        return <span className="badge sn-pending-text-tble"> {cell.archiveStatus} </span>;
                    case "ARCHIVED":
                        return <span className="badge sn-archived-text-tble"> {cell.archiveStatus} </span>;
                    default:
                        return <span>-</span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            selector: row => (
                <>
                    {row.archiveStatus === "ARCHIVED" ? <button className="btn btn-sm button-grey-main edit-list">
                        <a href={row.zipLink} download="Example-PDF-document" target="_blank" rel="noreferrer">
                            <i className="ri-download-2-line" />
                        </a>
                    </button> : ''}
                    {row.archiveStatus === "ARCHIVED" ? <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => deleteArchives(row?.id)}>
                        <i className="ri-delete-bin-5-fill align-bottom" />
                    </button> : ''}
                </>
            )
        },
    ];

    useEffect(() => {
        const calculateRemainingDays = () => {
            if (profileData?.subscriptionEndDate && profileData?.subscriptionStartDate) {
                const endDate = new Date(profileData?.subscriptionEndDate);
                const startDate = new Date(profileData?.subscriptionStartDate);
                const currentDate = new Date();

                const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
                const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24))
                setRemainingDays(remainingDays > 0 ? remainingDays : 0);
                const interval = setInterval(() => {
                    const newCurrentDate = new Date();
                    const newRemainingDays = Math.ceil((endDate - newCurrentDate) / (1000 * 60 * 60 * 24));
                    setRemainingDays(newRemainingDays);
                }, 1000 * 60 * 60 * 24);

                return () => clearInterval(interval);
            } else {
                setRemainingDays(null);
            }
        };

        calculateRemainingDays();
    }, [profileData]);


    useEffect(() => {
        const updatedIds = selectedEmployeeOptions?.map(userid => userid?.id);
        setEmployeeId(updatedIds);
    }, [selectedEmployeeOptions])


    // delete Archives
    const deleteArchives = async (getId) => {
        try {
            const data = {
                archiveId: getId
            };
            const config = {
                method: 'delete',
                url: `${DELETE_ARCHIVES}`,
                data
            };
            const response = await axios(config);
            if (response && response.success === true) {
                Toast(response?.message, "success");
                getArchives();
            }
        } catch (error) {
            if (!remainingDays === 0) {
                HandleErrorMessage(error);
            }
        }

    }


    const handleSubmit = () => {
        getArchives(currentPage, perPage)
        setCurrentPage(1)
        setPerPage(10);
    }

    useEffect(() => {
        getArchives(1);
    }, [EmployeeData])

    const handlePageChange = page => {
        setCurrentPage(page);
        getArchives(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getArchives(page, newPerPage)
        setPerPage(newPerPage);
    }

    const getArchives = async (page, size = perPage) => {
        setIsLoading(true);
        try {
            const defaultId = EmployeeData?.map(option => option?.id) || [];
            const data = {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                employeeIds: employeesId.length > 0 ? employeesId : defaultId,
            };
            const response = await api.create(`${GET_ARCHIVES}?pg=${page}&lm=${size}`, data);
            if (response && response.success === true) {
                const apiResponse = response.data
                setIsLoading(false)
                let tableData = (apiResponse?.resData || [])?.map(archive => ({
                    id: archive?._id,
                    employeeName: archive?.employeeName || 'unknown',
                    createdAt: archive?.createdAt || '-',
                    archiveSize: archive?.archiveSize || '-',
                    archiveStatus: archive?.archiveStatus || '-',
                    zipLink: archive?.zipLink
                }));
                setArchivesData(tableData);
                setTotalRows(apiResponse.totoalArchiveCount);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    }


    // reset button clear 
    const handleReset = () => {
        setSelectedToDate([])
        setSelectedFromDate([])
        setSelectedEmployeeOptions([]);
        setTeamIndu([]);
    }

    const handleClose = () => {
        setIsExpiredModalOpen(false);
        navigate("/planPackage");
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {(remainingDays === 0 && isExpiredModalOpen) && <SubExpired onClose={handleClose} profileData={profileData?.selectedPlanDetails?.planName} remainingDays={remainingDays} />}
            <div className="page-content sn-recording-archives-page">
                <Container fluid>
                    <BreadCrumb title='RECORDING ARCHIVES' pageTitle="Archives Recordings" />
                    <Row>
                        <Col lg={12}>
                            <Card className='h-100'>
                                {/* <CardHeader>
                                    <h4 className="card-title mb-0">Archives Recordings</h4>
                                </CardHeader> */}
                                <CardBody>
                                    <Row className="sn-header-select-employess-home sn-top-inputs-heading mb-0 pt-0">
                                        <div className="col-md-2 col-sm-12">
                                            <DatePickerWithoutTimes onChange={handleFromDateChange} getDate={selectedFromDate} labelContent='From Date' />
                                        </div>
                                        <div className="col-md-2 col-sm-12">
                                            <DatePickerWithoutTimes onChange={handleToDateChange} getDate={selectedToDate} labelContent='To Date' />
                                        </div>

                                        <div className="col-md-3 col-sm-12">
                                            <TeamSelect
                                                setSelectedEmployeeOptions={setSelectedEmployeeOptions}
                                                teamIndu={teamIndu} setTeamIndu={setTeamIndu} labelContent='Select Team' />
                                        </div>

                                        <div className="col-md-2 col-sm-12">
                                            <EmployeeSelect setEmployeeDefault={setEmployeeData} EmployeeDefault={EmployeeData} teamIndu={teamIndu} selectedEmployeeOptions={selectedEmployeeOptions}
                                                setSelectedEmployeeOptions={setSelectedEmployeeOptions} labelContent='Select Employee' />
                                        </div>


                                        <div className="col-md-3 col-sm-12">
                                            <FormGroup className="mt-2">
                                                <Label for="exampleSelect"></Label>
                                                <div id="exampleSelect" className="sn-call-apply-btn-reset">
                                                    <Button id="exampleSelect" type="submit" color="danger" className="" onClick={handleSubmit} disabled={isLoading} >
                                                        Apply
                                                    </Button>
                                                    <Button className="" style={{ marginLeft: "10px" }} id="exampleSelect" outline color="danger" onClick={handleReset} disabled={isLoading}>
                                                        Reset
                                                    </Button>
                                                </div>
                                            </FormGroup>
                                        </div>

                                    </Row>
                                    <Row className="sn-team-managment-tble mt-0 pt-0">
                                        <Col lg={12} className="mt-0 pt-0">
                                            <CommanDataTable className="mt-0 pt-0" handleSearch={handleSearch}
                                                column={column} tableData={archivesData} searchText={searchText}
                                                handlePerRowsChange={handlePerRowsChange}
                                                totalRows={totalRows} currentPage={currentPage}
                                                isLoading={isLoading}
                                                handlePageChange={handlePageChange}
                                                noData='There are no records to display'
                                                inputClass={(archivesData && archivesData.length > 0) || searchText ? 'd-block' : 'd-none'}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card> 
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CallArchivesReport