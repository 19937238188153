import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { NEVER_ATTENDED_REPORT } from "../../helpers/url_helper";
import CommanDataTable from "../../Components/Common/DataTable";
import moment from "moment";
import { useDispatch } from "react-redux";
import { DatePickerWithoutTimes } from "../../Components/Common/Flatpicker";
import TeamSelect from "../../Components/Common/TeamSelect";
import EmployeeSelect from "../../Components/Common/EmployeeSelect";
import { formatDate } from "../../Components/Common/FormDateTime";
import { getTeamEmployeeIncludeApi } from "../../store/dashboardCallCrm/getTeamEmployee/action";
import { APIClient } from "../../helpers/api_helper";
import {
  getPreviousDate,
  setCurrentDate,
} from "../../Components/Common/DefaultDateSet";
import { HandleErrorMessage } from "../../Components/Common/HandleErrorMessage";
import ModalNeverAttended from "../../Components/Common/ModalNeverAttended";
import { useProfileData } from "../../Components/Hooks/UserHooks";
import { useNavigate } from "react-router-dom";
import SubExpired from "../../Components/Common/subExpired";
import loaderimgmnew from "../../assets/images/loadersnc.gif";

const api = new APIClient();

const NeverAttendedCall = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { profileData } = useProfileData();
  const navigate = useNavigate();
  document.title = "Never Attended";
  const previewsDate = getPreviousDate();
  const currentDate = setCurrentDate();
  const dispatch = useDispatch();
  const [isExpiredModalOpen, setIsExpiredModalOpen] = useState(true);
  const permissionType = JSON.parse(localStorage.getItem("permission"));
  const viewReportPermission = permissionType?.includes("VIEW_REPORT");
  const loginType = JSON.parse(localStorage.getItem("logintype"));

  // date picker state
  const [selectedFromDate, setSelectedFromDate] = useState(previewsDate);
  const [selectedToDate, setSelectedToDate] = useState(currentDate);
  //select team state
  const [teamIndu, setTeamIndu] = useState([]);
  // employee select
  const [selectedEmployeeOptions, setSelectedEmployeeOptions] = useState([]);
  // Pagination settings
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // table data state

  const [neverAttendData, setNeverAttendData] = useState([]);

  const [remainingDays, setRemainingDays] = useState(null);

  // flatPicker Date
  const formattedStartDate = formatDate(selectedFromDate);
  const formattedEndDate = formatDate(selectedToDate);

  const [EmployeeData, setEmployeeData] = useState([]);

  const [modal_animationFlip, setmodal_animationFlip] = useState(false);
  const [outgoingCall, setOutgoingCall] = useState([]);

  //search field state
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  // From Date
  const handleFromDateChange = (dateStr) => {
    setSelectedFromDate(dateStr);
  };

  // To date
  const handleToDateChange = (dateStr) => {
    setSelectedToDate(dateStr);
  };

  useEffect(() => {
    const calculateRemainingDays = () => {
      if (
        profileData?.subscriptionEndDate &&
        profileData?.subscriptionStartDate
      ) {
        const endDate = new Date(profileData?.subscriptionEndDate);
        const startDate = new Date(profileData?.subscriptionStartDate);
        const currentDate = new Date();

        const totalDays = Math.ceil(
          (endDate - startDate) / (1000 * 60 * 60 * 24)
        );
        const remainingDays = Math.ceil(
          (endDate - currentDate) / (1000 * 60 * 60 * 24)
        );
        setRemainingDays(remainingDays > 0 ? remainingDays : 0);
        const interval = setInterval(() => {
          const newCurrentDate = new Date();
          const newRemainingDays = Math.ceil(
            (endDate - newCurrentDate) / (1000 * 60 * 60 * 24)
          );
          setRemainingDays(newRemainingDays);
        }, 1000 * 60 * 60 * 24);

        return () => clearInterval(interval);
      } else {
        setRemainingDays(null);
      }
    };

    calculateRemainingDays();
  }, [profileData]);

  useEffect(() => {
    let teamType = teamIndu?.map((item) => item?.value) || [];
    if (teamType?.length > 0) {
      setIsLoading(false);
      const newTeam = { teamName: teamType };
      dispatch(getTeamEmployeeIncludeApi(newTeam));
    }
  }, [teamIndu, dispatch]);



  const column = [
    {
      name: <span className="font-weight-bold fs-13"> SN.</span>,
      width: "6%",
      selector: (row, index) => index + 1,
      sortable: true, // This sets the width of the "Sr. No" column to 30px
    },

    {
      name: (
        <span
          className="font-weight-bold fs-13 text-center fs-2"
          style={{ textTransform: "capitalize" }}
        >
          Employee
        </span>
      ),
      selector: (row) => row?.fullName,
      cell: (row) => (
        <div className="mt-2 mb-2">
          <h5
            className="font-weight-bold fs-13"
            style={{ textTransform: "capitalize" }}
          >
            {row?.fullName}
          </h5>
          <h6 className="text-center- font-weight-bold fs-13">
            {row?.mobileNumber}
          </h6>
        </div>
      ),
      sortable: true,
    },


    {
      name: (
        <span
          className="font-weight-bold fs-13 text-center fs-2 fs-2"
          style={{ textTransform: "capitalize" }}
        >
          Client Name
        </span>
      ),
      selector: (row) => row?.name,
      cell: (row) => (
        <div className="mt-2 mb-2">
          <h5
            className="font-weight-bold fs-13"
            style={{ textTransform: "capitalize" }}
          >
            {row?.name}
          </h5>
          <h6 className="text-center- font-weight-bold fs-13">
            {row?.phoneNumber}
          </h6>
        </div>
      ),
      sortable: true,
    },

    {
      name: <span className="font-weight-bold fs-13 fs-2">Date</span>,
      selector: (row) => row?.date,
      cell: (row) => (
        <div className="timeSet">
          {row?.date?.map((callDate, index) => (
            <React.Fragment key={index}>
              <span>{moment(callDate.date).format("DD MMM YYYY")}</span>
              <br />
              {index !== row.date.length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      ),
      sortable: true,
    },


    {
      name: <span className="font-weight-bold fs-13 fs-2">Time</span>,
      selector: (row) => (
        <div className="timeSet">
          {row?.time?.map((callTime, index) => {
            const momentObj = moment(callTime.date).format("hh:mm A");
            return (
              <React.Fragment key={index}>
                <span className="gap-2">{momentObj}</span>
                <br />
                {index !== row.time.length - 1 && <br />}
              </React.Fragment>
            );
          })}
        </div>
      ),
      // sortable: true
    },

    {
      name: <span className="font-weight-bold fs-13 fs-2">Call Type</span>,
      selector: (row) => (
        <div className="timeSet">
          {row?.dataAndType?.every(
            (callType) =>
              callType?.type !== "MISSED" && callType?.type !== "REJECTED"
          ) ? (
            <span style={{ textAlign: "center" }}>-</span>
          ) : (
            row.type.map((callType, index) => {
              let content;
              switch (callType.type) {
                case "MISSED":
                  content = (
                    <>
                      <div className="sn-call-type-newer-call">
                        <i className="mdi mdi-phone-missed sn-out-missed-text sn-never-attended-icon-mm" />
                        <span className="gap-2 sn-out-missed-call">
                          {callType.type}
                        </span>
                      </div>
                    </>
                  );
                  break;
                case "REJECTED":
                  content = (
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "medium",
                        fontWeight: "bold",
                        color: "#e97dfb",
                      }}
                    >
                      <div className="sn-call-type-newer-call">
                        <i className="ri-indeterminate-circle-line rejected-icon-never" />
                        <span
                          className="gap-2 sn-out-missed-call"
                          style={{
                            color: "#e97dfb",
                            background: "rgb(233 125 251 / 10%)",
                          }}
                        >
                          {callType.type}
                        </span>
                      </div>
                    </div>
                  );
                  break;
                default:
                  content = null; // No content for other types
              }

              return (
                <React.Fragment key={index}>
                  {content}
                  {index !== row.type.length - 1 && <br />}
                </React.Fragment>
              );
            })
          )}
        </div>
      ),
    },

    {
      name: (
        <span
          className="font-weight-bold fs-13 text-center fs-2 fs-2"
          style={{ textTransform: "capitalize" }}
        >
          Attempt to Call
        </span>
      ),
      selector: (row) => {
        return (
          <>
            <h5
              className="font-weight-bold fs-13"
              style={{
                textTransform: "capitalize",
                color: "blue",
                fontWeight: "900",
                cursor: "pointer",
              }}
              onClick={() => {
                if (row.attempt > 0) {
                  showModal(row);
                }
              }}
            >
              <span
                style={{
                  color: row.attempt > 0 ? "inherit" : "red",
                  cursor: "inherit",
                }}
              >
                {row.attempt > 0 ? `${row.attempt} Times` : "Not Attempted"}
              </span>
            </h5>
          </>
        );
      },
      sortable: true,
    },
  ];


  const handleSearch = (e) => {
    setQuery(e.target.value);
  };


  useEffect(() => {
    const defaultId = EmployeeData?.map((option) => option?.id) || [];
    neverAttendedData(defaultId, "");
  }, [EmployeeData]);


  // table data
  const handleSubmit = () => {
    neverAttendedData(currentPage, perPage);
    setCurrentPage(1)
    setPerPage(10);
  };

  // Debounce search query

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedQuery.length >= 3 || debouncedQuery.length === 0) {
        await neverAttendedData(currentPage, perPage, debouncedQuery);
      }
    };

    fetchData();
  }, [debouncedQuery, currentPage, perPage]);



  const showModal = async (row) => {
    setOutgoingCall(row);
    setmodal_animationFlip(true);
  };

  // Pagination handler
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  
  const neverAttendedData = async (page, size = perPage, searchTerm = '') => {
    setIsLoading(true);
    try {
      const updatedIds = selectedEmployeeOptions.map((userid) => userid?.id);
      let data = {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        employeeIds: updatedIds,
      };
      const response = await api.create(`${NEVER_ATTENDED_REPORT}?pg=${page}&lm=${size}&query=${searchTerm}`, data);
      if (response && response?.success === true) {
        setIsLoading(false);
        const apiResponse = response?.data[0];
        const tableData = (apiResponse?.paginatedResults || [])?.map(
          (data, index) => ({
            name: data.name || "unknown",
            mobileNumber: data.empDescription[0]?.mobileNumber || "-",
            phoneNumber: data._id.phoneNumber || "-",
            fullName: `${data.empDescription[0]?.fullName || "unknown"}`,
            attempt: data.attempt || '-',
            date: data?.dataAndType || "-",
            time: data.dataAndType || "-",
            type: data.dataAndType || "-",
          })
        );
        setNeverAttendData(tableData);
        setTotalRows(apiResponse?.totalCount);
      }
    } catch (error) {
      if (!remainingDays === 0) {
        HandleErrorMessage(error);
      }
    } finally {
      setIsLoading(false);
    }
  };


  // clear field
  const resetInputHistory = () => {
    setSelectedToDate("");
    setSelectedFromDate("");
    setSelectedEmployeeOptions([]);
    setTeamIndu([]);
  };


  const handleClose = () => {
    setIsExpiredModalOpen(false);
    navigate("/planPackage");
  };

  return (
    <React.Fragment>
      {remainingDays === 0 && isExpiredModalOpen && (
        <SubExpired
          onClose={handleClose}
          profileData={profileData?.selectedPlanDetails?.planName}
          remainingDays={remainingDays}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Never Attended" pageTitle="Never Attended" />
          <Row>
            <Col lg={12}>
              <Card className="h-100">
                <CardBody>
                  <Row className="sn-header-select-employess-home sn-top-inputs-heading">
                    <div className="col-md-2 col-sm-12">
                      <DatePickerWithoutTimes
                        onChange={handleFromDateChange}
                        getDate={selectedFromDate}
                        labelContent="From Date"
                      />
                    </div>
                    <div className="col-md-2 col-sm-12">
                      <DatePickerWithoutTimes
                        onChange={handleToDateChange}
                        getDate={selectedToDate}
                        labelContent="To Date"
                      />
                    </div>
                    {((viewReportPermission && loginType === "employee") ||
                      loginType === "organization") && (
                        <div className="col-md-2 col-sm-12">
                          <TeamSelect
                            setSelectedEmployeeOptions={
                              setSelectedEmployeeOptions
                            }
                            teamIndu={teamIndu}
                            setTeamIndu={setTeamIndu}
                            labelContent="Select Team"
                          />
                        </div>
                      )}
                    {((viewReportPermission && loginType === "employee") ||
                      loginType === "organization") && (
                        <div className="col-md-3 col-sm-12">
                          <EmployeeSelect
                            EmployeeDefault={EmployeeData}
                            setEmployeeDefault={setEmployeeData}
                            teamIndu={teamIndu}
                            selectedEmployeeOptions={selectedEmployeeOptions}
                            setSelectedEmployeeOptions={
                              setSelectedEmployeeOptions
                            }
                            labelContent="Select Employee"
                          />
                        </div>
                      )}
                    <div className="col-md-3 col-sm-12">
                      <FormGroup className="mt-2">
                        <Label for="exampleSelect"></Label>
                        <div
                          id="exampleSelect"
                          className="sn-call-apply-btn-reset"
                        >
                          <Button
                            id="exampleSelect"
                            type="submit"
                            color="danger"
                            className=""
                            onClick={handleSubmit}
                          // disabled={isLoading}
                          >
                            Apply
                          </Button>
                          <Button
                            className=""
                            style={{ marginLeft: "10px" }}
                            id="exampleSelect"
                            outline
                            color="danger"
                            onClick={resetInputHistory}
                            disabled={isLoading}
                          >
                            Reset
                          </Button>
                        </div>
                      </FormGroup>
                    </div>
                  </Row>
                
                  <Row className="sn-team-managment-tble sn-user-tble-manin-manage">               
                    <Col lg={12}>
                      <CommanDataTable
                        handleSearch={handleSearch}
                        column={column}
                        searchText={query}
                        tableData={neverAttendData}
                        handlePerRowsChange={handlePerRowsChange}
                        totalRows={totalRows}
                        currentPage={currentPage}
                        isLoading={isLoading}
                        handlePageChange={handlePageChange}
                        inputClass={
                          (neverAttendData && neverAttendData.length > 0) ||
                          query
                            ? "d-block"
                            : "d-none"
                        }
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ModalNeverAttended
            outgoingCall={outgoingCall}
            modal_animationFlip={modal_animationFlip}
            setmodal_animationFlip={setmodal_animationFlip}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NeverAttendedCall;
