import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  GET_EMPLOYEE,
  POST_GET_INDUSTRY_TYPE,
  SUBSCRIPTION_PLAN,
} from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
import { useLocation } from "react-router-dom";
import ModalManageUser from "./ModalManageUser";
import { toast, ToastContainer } from "react-toastify";
import { useProfileData } from "../../Components/Hooks/UserHooks";
import { getCurrencyExchangeRate } from "../../Components/Common/ApiUtils";
const api = new APIClient();

const SubscriptionPlan = () => {
  const { profileData } = useProfileData();
  const location = useLocation();
  const planType = location?.state?.data;
  const { type, pricePerGB, pricingPerUser } = planType || {};


  console.log(pricePerGB,"pricePerGB");
  

  const [employeeSelect, setEmployeeSelecteded] = useState([]);
  const [transactionType, setTransactionType] = useState([]);

  const [amountPerUserInr, setAmountPerUserInr] = useState(null);
 
  //modal open
  const [addTeamModal, setAddTeamModal] = useState(false);
  const [numberEmployee, setNumberEmployee] = useState(null);
  const [storage, setStorage] = useState();
  const [perEmployee, setPerEmployee] = useState([]);

  const handleInputChange = (e) => {
    const numEmployees = parseInt(e.target.value);
    if (isNaN(numEmployees) || numEmployees <= 0) {
      setNumberEmployee("");
      setStorage("");
      return;
    } else {
      setNumberEmployee(numEmployees);
    }
  };

  const selectHandleChange = (e) => {
    const selectedStorage = parseInt(e.target.value);
    if (isNaN(numberEmployee) || numberEmployee <= 0) {
      toast.error("Please enter a valid number of users", { autoClose: 1000 });
      setNumberEmployee("");
      setStorage("");
      return;
    } else {
      setStorage(selectedStorage);
    }
  };

  const getEmployeeData = async () => {
    try {
      const response = await api.get(`${GET_EMPLOYEE}`);
      if (response.success === true) {
        setPerEmployee(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = useCallback(async () => {
    if (isNaN(numberEmployee) || numberEmployee <= 0) {
      toast.error("Please enter  number of Users", { autoClose: 1000 });
      return;
    } else {
      let trplanType = "";
      if (numberEmployee && storage) {
        trplanType = transactionType?.SUBSCRIPTION_BOTH;
      } else if (numberEmployee) {
        trplanType = transactionType?.SUBSCRIPTION_EMPLOYEE;
      }
      masterData();
      try {
        const data = {
          planType: type,
          employeeIds: employeeSelect,
          numberOfEmployee: numberEmployee || 0,
          storage: storage || 0,
          transactionType: trplanType,
        };
        const response = await api.create(`${SUBSCRIPTION_PLAN}`, data);
        if (response?.success === true) {
          const res = response?.data.pGateWayResponse;
          if (res?.order?.url) {
            window.open(res.order.url, "_blank", "noopener,noreferrer");
          } else {
            console.error("URL not found in API response");
          }
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message, { autoClose: 1000 });
      }
    }
  }, [employeeSelect, transactionType, numberEmployee, storage, type]);

  const createTeam = () => {
    setAddTeamModal(true);
  };

  useEffect(() => {
    getCurrencyExchangeRate(setAmountPerUserInr); // Run the API call once when the component mounts
    masterData();
    getEmployeeData();
  }, []);


 


  const masterData = async () => {
    try {
      const response = await api.get(`${POST_GET_INDUSTRY_TYPE}`);
      if (response.success === true) {
        const res = response?.data?.transactionTypeConstant;
        setTransactionType(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const totalUsersAmount = parseInt(numberEmployee) * pricingPerUser || 0;
  const totalStorageAmount = parseInt(storage) * pricePerGB || 0;
  const totalAmount = totalUsersAmount + totalStorageAmount;
  const amountinr = totalAmount * amountPerUserInr;

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="" pageTitle="Pages" />
          <Row>
            <Col xl={6}>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardHeader>
                      <span className="float-end text-secondary">
                        AED {pricingPerUser}/User/Month
                      </span>
                      <div className="form-check mb-2">
                        <Label
                          className="form-check-label fs-5"
                          htmlFor="formCheck1"
                        >
                          Number of Users
                        </Label>
                      </div>
                      <p className="text-muted mt-3">
                        Empower your organization with seamless user management.
                        Add users as needed to enhance your Call Management
                        capabilities.
                      </p>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xl={6}>
                          <div className="input-group">
                            <Label className="input-group-text">
                              Number of Users
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              placeholder=""
                              value={numberEmployee}
                              onChange={handleInputChange}
                              onWheel={(e) => e.target.blur()}
                            />
                          </div>
                          <div className="mt-3">
                            {profileData.selectedPlan === "free" ? (
                              ""
                            ) : (
                              <p>
                                Currently you have {perEmployee?.length} out of{" "}
                                {profileData?.organizationStrength} employee(s).
                              </p>
                            )}
                          </div>
                        </Col>
                        {profileData.selectedPlan === "free" ? (
                          ""
                        ) : (
                          <Col xl={6}>
                            <Button
                              className="float-end rounded-pill"
                              color="danger"
                              onClick={() => createTeam()}
                            >
                              Manage Users
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-0">
                <Col xl={12}>
                  <Label className="form-check-label fs-5" htmlFor="formCheck1">
                    Add-ons
                  </Label>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xl={12}>
                  <Card>
                    <CardHeader>
                      <span className="float-end text-secondary">
                        AED {pricePerGB}/GB/Month
                      </span>
                      <div className="form-check">
                        <Label
                          className="form-check-label fs-5"
                          htmlFor="formCheck2"
                        >
                          Call Recording Storage
                        </Label>
                      </div>
                      <p className="text-muted mt-3">
                        Enhance your Call Management experience by adding
                        storage capacity in GB for seamless call recording.
                      </p>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xl={6}>
                          <div className="input-group">
                            <Label
                              className="input-group-text"
                              htmlFor="storageSelect"
                            >
                              Storage
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              placeholder=""
                              value={storage}
                              onChange={selectHandleChange}
                              onWheel={(e) => e.target.blur()}
                            />{" "}
                            &nbsp;<span className="mt-2">GB</span>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xxl={6}>
              <Card className="card-height-100--">
                <CardBody className="p-0">
                  <div className="p-3 bg-soft-warning">
                    <div className="float-end ms-2"></div>
                    <h6 className="mb-0 text-danger">Subcription Summary</h6>
                  </div>
                  <div className="p-3">
                    <div className="mt-3 pt-2">
                      <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                          <p className="fs-13 mb-0">Number of Users</p>
                          {numberEmployee && parseInt(numberEmployee) > 0
                            ? `${parseInt(numberEmployee)} × AED 24`
                            : "0"}
                        </div>
                        <div className="flex-shrink-0">
                          <h6 className="mb-0">AED {totalUsersAmount}</h6>
                        </div>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                          <p className="fs-13 mb-0">Call Recording Storage</p>
                          {storage && parseInt(storage) > 0
                            ? `${parseInt(storage)} GB × AED ${pricePerGB}`
                            : "0"}
                        </div>
                        <div className="flex-shrink-0">
                          <h6 className="mb-0">AED {totalStorageAmount}</h6>
                        </div>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                          <p className="fs-13 mb-0">Total Amount</p>
                        </div>
                        <div className="flex-shrink-0">
                          <h6 className="mb-0">AED {totalAmount}</h6>
                        </div>
                      </div>
                      {totalAmount && (
                        <div className="alert alert-danger" role="alert">
                          The payment will be processed in AED, but for your
                          convenience, the final amount is approximately ₹{" "}
                          {parseInt(amountinr)} based on current rates
                        </div>
                      )}
                    </div>
                    <div className="mt-3 pt-2">
                      <Button
                        className="btn btn-danger w-100"
                        onClick={handleClick}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <ModalManageUser
              setEmployeeSelecteded={setEmployeeSelecteded}
              addTeamModal={addTeamModal}
              setAddTeamModal={setAddTeamModal}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SubscriptionPlan;
