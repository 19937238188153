export const toCamelCase = (str) => {
    return str
        .split(' ')
        .map((word, index) => {
            if (index === 0) {
                return word.toLowerCase();
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ');
};

export const sortTeamsByMemberCount = (teams, order = 'asc') => {
    return teams.sort((a, b) => {
        if (order === 'asc') {
            return a.memberCount - b.memberCount;
        } else if (order === 'desc') {
            return b.memberCount - a.memberCount;
        }
        return 0;
    });
};

export const sortMemberCount = (a, b) => {
    // Convert the memberCount to integers if they are not already numbers
    const memberCountA = parseInt(a.memberCount, 10);
    const memberCountB = parseInt(b.memberCount, 10);

    // Prioritize zeros
    if (memberCountA === 0 && memberCountB !== 0) {
        return -1;
    }
    if (memberCountB === 0 && memberCountA !== 0) {
        return 1;
    }

    // Sort normally
    return memberCountA - memberCountB;
};

export const getRelativeTime = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) {
        return "";
    }
    const now = Date.now();
    const timeDifference = now - timestamp;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} day ago`;
    } else if (hours > 0) {
        return `${hours} hour(s) ago`;
    } else if (minutes > 0) {
        // return `${minutes} minute(s) ago`;
        return `${minutes} minute`;
    } else if (seconds > 0) {
        return `${seconds} second(s) ago`;
    } else {
        return "Just now";
    }
};


export const calculateDate = (startDate) => {
    const date = new Date(startDate);
    date.setMonth(date.getMonth() + 1); // Add 1 month
    return date;
};

export const letterColors = {
    A: "#3975d8",
    B: "#a1bfff",
    C: "#d4bf56",
    D: "#8bc070",
    E: "#47b0a9",
    F: "#6e5b7c",
    G: "#3ca06a",
    H: "#98999b",
    I: "#aa90b3",
    J: "#95950e",
    K: "#408d79",
    L: "#992b2e",
    M: "#ee9c64",
    N: "#3488a4",
    O: "#cc5984",
    P: "#d09d17",
    Q: "#cc5984",
    R: "#4b66c5",
    S: "#72252d",
    T: "#457425",
    U: "#5d6066",
    V: "#6d8a51",
    W: "#1a5985",
    X: "#cca9f6",
    Y: "#dfb432",
    Z: "#2da9a6",
};