import React, {useEffect, useState} from "react";
import axios from "axios";
import bgAuth1 from "../../assets/images/avatar-1.jpg";
import bgAuth2 from "../../assets/images/avatar-2.jpg";
import bgAuth3 from "../../assets/images/avatar-3.jpg";
import bgAuth4 from "../../assets/images/avatar-4.jpg";
import bgAuth5 from "../../assets/images/avatar-5.jpg";
import bgAuth6 from "../../assets/images/avatar-6.jpg";
import bgAuth7 from "../../assets/images/avatar-7.jpg";
import bgAuth8 from "../../assets/images/avatar-8.jpg";
import bgAuth from "../../assets/images/multi-user.png";
import starwin1 from "../../assets/images/trophy-star.png";
import starwin2 from "../../assets/images/trophy.png";
import starwin3 from "../../assets/images/trophy333.png";
import {useNavigate} from "react-router-dom";
import {Tooltip} from "reactstrap";
//callerData
const TopPerformanceEmployee = ({formattedDates}) => {
    const [tooltiptopCaller, setTooltipAgent] = useState(false);
    const toggleAgent = () => setTooltipAgent(!tooltiptopCaller);

    const [tooltiptopDialer, setTooltipDialer] = useState(false);
    const toggleADialertop = () => setTooltipDialer(!tooltiptopDialer);

    const [tooltiptopHighest, setTooltipHighest] = useState(false);
    const toggleAHighest = () => setTooltipHighest(!tooltiptopHighest);

    const [tooltiptopAnswered, setTooltipAnswered] = useState(false);
    const toggleAAnswered = () => setTooltipAnswered(!tooltiptopAnswered);

    const [topCaller, setTopCaller] = useState({
        fullName: "John Doe",
        callCount: 499,
        profileImageUrl: "",
    });

    const loginType = JSON.parse(localStorage.getItem("logintype"));

    //sortedCallers
    const [topDialer, setTopDialer] = useState([]);
    const [totalConnect, setTotalConnect] = useState({totalDuration: 0});
    const [totalAnswer, setTotalAnswer] = useState([]);
    const convertToTimestamp = (dateStr) => {
        return new Date(dateStr).getTime();
    };
    const navigate = useNavigate();

    //For navigation

    const handleNavigationToTopCallers = () => {
        navigate("/topCallers-report");
    };
    const handleNavigationToTopDialers = () => {
        navigate("/topDialers-report");
    };
    const handleNavigationTopDuration = () => {
        navigate("/topCallDuration-report");
    };
    const handleNavigationTotalConnected = () => {
        navigate("/topConnected-report");
    };
    // Default to current timestamp for the first API call
    const fetchCallerData = async (startTime, endTime) => {
        try {
            const response = await axios.get(
                `https://service.salesninjacrm.com/organization/top-callers/${startTime}/${endTime}`
            );
            if (response.data && response.data.length > 0) {
                const topCaller = response.data[0];
                setTopCaller({
                    fullName: topCaller?.fullName,
                    callCount: topCaller?.callCount,
                    profileImageUrl: topCaller?.profileImageUrl,
                });

                const topDialer = response.data.sort((a, b) => b.totalOutgoing - a.totalOutgoing);
                setTopDialer(topDialer[0]);

                const sortedTotal = response.data.sort((a, b) => b.totalDuration - a.totalDuration);
                setTotalConnect(sortedTotal[0]);

                const sortedTotalAnswer = response.data.sort((a, b) => b.totalAnswered - a.totalAnswered);
                setTotalAnswer(sortedTotalAnswer[0]);
            }
        } catch (error) {
            console.error("Error fetching caller data:", error);
        }
    };

    // Call the API with current timestamps initially
    const currentTimestamp = Date.now();
    const todayStart = new Date(); // Get today's date
    todayStart.setHours(0, 0, 0, 0); // Set to 00:00 AM

    useEffect(() => {
        fetchCallerData(todayStart.getTime(), currentTimestamp);
    }, []);

    useEffect(() => {
        if (formattedDates?.startDate && formattedDates?.endDate) {
            const newStartTime = convertToTimestamp(formattedDates.startDate);
            const newEndTime = convertToTimestamp(formattedDates.endDate);

            // Fetch data with new formatted dates
            fetchCallerData(newStartTime, newEndTime);
        }
    }, [formattedDates]); // Fetch data whenever formattedDates changes

    const formatDuration = (duration) => {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = duration % 60;

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <div>
            <div className="row">
                {/* <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="bg-white rounded-1">
            <div className="card-body sn-topPerformance-design-sec">
              <div className="win-people-headng">
                <div>
                  <h5>Today's Score</h5>
                  <p className=".text-body-secondary">Sales Ninja CRM</p>
                </div>
                <div>
                  <a
                    className="text-decoration-underline link-secondary"
                    href="javascript:;"
                  >
                    View All
                  </a>
                </div>
              </div>
              <div className="sn-winlist-employees">
                <ul>
                  <li>
                    <img src={bgAuth5} className="card-animate" />
                    <h6>1</h6>
                    <p>
                      <img
                        src={starwin2}
                        alt="Star"
                        className="sn-star-empolyee-awardimg"
                      />
                    </p>
                    <h4>William Barbara</h4>
                    <h3>
                      <span>Total</span>: 99%
                    </h3>
                  </li>
                  <li>
                    <img src={bgAuth2} className="card-animate" />
                    <h6>2</h6>
                    <p>
                      <img
                        src={starwin3}
                        alt="starwin1"
                        className="sn-star-empolyee-awardimg"
                      />
                    </p>
                    <h4>William Barbara</h4>
                    <h3>
                      <span>Total</span>: 99%
                    </h3>
                  </li>
                  <li>
                    <img src={bgAuth3} className="card-animate" />
                    <h6>3</h6>
                    <p>
                      <img
                        src={starwin1}
                        alt="Star"
                        className="sn-star-empolyee-awardimg"
                      />
                    </p>
                    <h4>William Barbara</h4>
                    <h3>
                      <span>Total</span>: 99%
                    </h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
                {loginType === "organization" && (
                    <div className="col-md-12 col-sm-12">
                        <div className="row sn-top-cannected-employee">
                            <div className="col-md-3 col-sm-3 col-xs-12">
                                <div
                                    className="card-animate card"
                                    style={{
                                        minHeight: "100px",
                                        maxHeight: "200px",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        className="card-body"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                    Top Caller
                                                    <span
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        id="DisableTopCaller"
                                                        className="ri-information-line sn-dashboard-tble-toll"
                                                    ></span>
                                                    <div>
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={tooltiptopCaller}
                                                            autohide={false}
                                                            target="DisableTopCaller"
                                                            toggle={toggleAgent}
                                                            style={{maxWidth: "300px"}}
                                                        >
                                                            Employee with the highest total calls (incoming and
                                                            outgoing) today.
                                                        </Tooltip>
                                                    </div>
                                                </p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <h5 className="fs-16 mb-0 text-black">{topCaller.fullName}</h5>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                                    <span className="counter-value" data-target="559.25">
                                                        <span>{topCaller.callCount}</span>
                                                    </span>
                                                </h4>
                                                <a
                                                    className="link-secondary"
                                                    href="javascript:;"
                                                    onClick={handleNavigationToTopCallers}
                                                >
                                                    <span className="ri-arrow-right-line sn-top-caller-btn"></span>
                                                </a>
                                            </div>
                                            <div className="avatar-sm flex-shrink-0 total-connected-call-first-people mb-4">
                                                <img
                                                    src={topCaller.profileImageUrl || bgAuth}
                                                    alt="Profile"
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        objectFit: "cover",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-3 col-xs-12">
                                <div
                                    className="card-animate card"
                                    style={{
                                        minHeight: "100px",
                                        maxHeight: "200px",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        className="card-body"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                    Top Dialer
                                                    <span
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        id="DisableTopDialer"
                                                        className="ri-information-line sn-dashboard-tble-toll"
                                                    ></span>
                                                    <div>
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={tooltiptopDialer}
                                                            autohide={false}
                                                            target="DisableTopDialer"
                                                            toggle={toggleADialertop}
                                                            style={{maxWidth: "300px"}}
                                                        >
                                                            Employee with the most outgoing calls made today.
                                                        </Tooltip>
                                                    </div>
                                                </p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <h4 className="fs-16 mb-0 text-black">
                                                    {topDialer.fullName || "John Doe"}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                                    <span className="counter-value" data-target="559.25">
                                                        <span>{topDialer.totalOutgoing || "500"}</span>
                                                    </span>
                                                </h4>
                                                <a
                                                    className="link-secondary"
                                                    href="javascript:;"
                                                    onClick={handleNavigationToTopDialers}
                                                >
                                                    <span className="ri-arrow-right-line sn-top-caller-btn"></span>
                                                </a>
                                            </div>
                                            <div className="avatar-sm flex-shrink-0 total-connected-call-first-people mb-4">
                                                <img
                                                    src={topDialer.profileImageUrl || bgAuth}
                                                    alt="Profile"
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        objectFit: "cover",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-3 col-xs-12">
                                <div
                                    className="card-animate card"
                                    style={{
                                        minHeight: "100px",
                                        maxHeight: "200px",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        className="card-body"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                    Highest Call Duration
                                                    <span
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        id="DisableTopHighest"
                                                        className="ri-information-line sn-dashboard-tble-toll"
                                                    ></span>
                                                    <div>
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={tooltiptopHighest}
                                                            autohide={false}
                                                            target="DisableTopHighest"
                                                            toggle={toggleAHighest}
                                                            style={{maxWidth: "300px"}}
                                                        >
                                                            Employee with the longest cumulative call time today across
                                                            all calls.
                                                        </Tooltip>
                                                    </div>
                                                </p>
                                                <p></p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <h4 className="fs-16 mb-0 text-black">
                                                    {totalConnect.fullName || "John Doe"}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end justify-content-between mt-1">
                                            <div>
                                                <h4 className="fs-22 fw-semibold ff-secondary mb-3">
                                                    <span className="counter-value" data-target="559.25">
                                                        <span>
                                                            {totalConnect.totalDuration ||
                                                            totalConnect.totalDuration === 0
                                                                ? formatDuration(totalConnect.totalDuration)
                                                                : "0h 0m 0s"}
                                                        </span>
                                                    </span>
                                                </h4>
                                                <a
                                                    className="link-secondary"
                                                    href="javascript:;"
                                                    onClick={handleNavigationTopDuration}
                                                >
                                                    <span className="ri-arrow-right-line sn-top-caller-btn"></span>
                                                </a>
                                            </div>
                                            <div className="avatar-sm flex-shrink-0 total-connected-call-first-people mb-4">
                                                <img
                                                    src={totalConnect.profileImageUrl || bgAuth}
                                                    alt="Profile"
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        objectFit: "cover",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-3 col-xs-12">
                                <div
                                    className="card-animate card"
                                    style={{
                                        minHeight: "100px",
                                        maxHeight: "220px",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        className="card-body"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 overflow-hidden">
                                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                    Top Answered
                                                    <span
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        id="DisableTopAnswered"
                                                        className="ri-information-line sn-dashboard-tble-toll"
                                                    ></span>
                                                    <div>
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={tooltiptopAnswered}
                                                            autohide={false}
                                                            target="DisableTopAnswered"
                                                            toggle={toggleAAnswered}
                                                            style={{maxWidth: "300px"}}
                                                        >
                                                            Employee who received and answered the highest number of
                                                            calls today.
                                                        </Tooltip>
                                                    </div>
                                                </p>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <h4 className="fs-16 text-black mb-0 ">
                                                    {totalAnswer.fullName || "John Doe"}
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                                    <span className="counter-value" data-target="559.25">
                                                        <span>{totalAnswer.totalAnswered || 499}</span>
                                                    </span>
                                                </h4>
                                                <a
                                                    className="link-secondary"
                                                    href="javascript:;"
                                                    onClick={handleNavigationTotalConnected}
                                                >
                                                    <span className="ri-arrow-right-line sn-top-caller-btn"></span>
                                                </a>
                                            </div>
                                            <div className="avatar-sm flex-shrink-0 total-connected-call-first-people mb-4">
                                                <img
                                                    src={totalAnswer.profileImageUrl || bgAuth}
                                                    alt="Profile"
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        objectFit: "cover",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div> 
        </div>
    );
};

export default TopPerformanceEmployee;
