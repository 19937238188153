import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const SelectDatePicker = ({ selectedDates, setSelectedDates }) => {
    const onRangeChange = (dates) => {
        console.log(dates,"dates");
        
        if (dates && dates.length === 2) {
            setSelectedDates([
                dayjs(dates[0]),
                dayjs(dates[1]),
            ]);
        }
    };
 
    const rangePresets = [
        {
            label: 'Today',
            value: [dayjs().add(-0, 'd'), dayjs()],
        },
        {   
            label: 'Yesterday',
            value: [dayjs().add(-1, 'd'), dayjs()],
        },
        {
            label: 'Last 3 Days',
            value: [dayjs().add(-3, 'd'), dayjs()],
        },
        {
            label: 'Last 7 Days',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'Last 14 Days',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: 'Last 30 Days',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'Last 90 Days',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ];

    return (
        <>
            <RangePicker
                presets={rangePresets}
                defaultValue={selectedDates}
                format="YYYY-MM-DD hh:mm A" // Include date and time format in 12-hour format
                onChange={onRangeChange}
                style={{ width: '100%' }}
                dropdownClassName="createDateRangePicker"
                showTime={{
                    format: 'hh:mm A', // Enable 12-hour time format
                    use12Hours: true, // 12-hour time with AM/PM
                }}
            />

        </>
    )
}

export default SelectDatePicker;