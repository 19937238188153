// import { Tooltip } from "chart.js";
import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Tooltip } from 'reactstrap';
import { formatDuration } from "./convertTimestampToTime";

const DashboardEmployeeSummary = ({ piechartData, EmployeeDefault }) => {

  const [tooltipOpenone, setTooltipOpen] = useState(false);
  const toggleFirst = () => setTooltipOpen(!tooltipOpenone);

  const [tooltipOpentwo, setTooltipTwo] = useState(false);
  const toggleSecond = () => setTooltipTwo(!tooltipOpentwo);

  const [tooltipOpenThird, setTooltipThird] = useState(false);
  const toggleThird = () => setTooltipThird(!tooltipOpenThird);

  const avgCallDurationInSeconds = piechartData.totalCallDuration;

  return (
    <React.Fragment>
      <Row className="sn-avg-unique-mumber-call">
        <Col lg={4} md={6} xs={12}>
          <Card className="avg-call-duration card-animate">

            <CardBody>
              <div className="d-flex align-items-center">
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className="avatar-title text-primary rounded-circle fs-3"
                    style={{ backgroundColor: "#16cbbd1c" }}
                  >
                    <i className="ri-numbers-line " style={{ color: '#16cbbd' }}></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-3">
                  <p
                    className="text-capitalize  fs-12  mb-1"
                    style={{ fontWeight: "900" }}
                  >
                    Total Call Duration
                  </p>
                  <h4 className="mb-0 fw-bold">
                    <div>
                      {avgCallDurationInSeconds ? formatDuration(avgCallDurationInSeconds): '00:00:00'}
                    </div>
                    <div className="dashboard-tooltip-eyes" data-bs-toggle="tooltip" data-bs-placement="top"
                      id="DisabledAutoHideOne"><span className="ri-information-line"></span></div>
                    <div>

                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpenone}
                        autohide={false}
                        target="DisabledAutoHideOne"
                        toggle={toggleFirst}
                      >
                        Total Call Duration shows the total time spent on all incoming and outgoing calls
                      </Tooltip>
                    </div>
                  </h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col lg={4} md={6} xs={12}>
          <Card className="avg-unique-call card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className="avatar-title text-primary rounded-circle fs-3"
                    style={{ backgroundColor: "#265c2e1f" }}
                  >
                    <i className="las la-phone-volume " style={{ color: '#265c2e' }}></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-3">
                  <p
                    className="text-capitalize fs-12 mb-1"
                    style={{ fontWeight: "900" }}
                  >
                    Total Connected Call
                  </p>
                  <h4 className="mb-0 fw-bold">
                    {" "}
                    <div>
                      {piechartData?.totalConnectedCalls}
                    </div>

                    <div className="dashboard-tooltip-eyestwo" data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideTwo">
                      <span className="ri-information-line">
                      </span>
                    </div>
                    <div>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpentwo}
                        autohide={false}
                        target="DisabledAutoHideTwo"
                        toggle={toggleSecond}
                      >
                        Shows unique numbers contacted, tracking the exact number of clients reached by your employees
                      </Tooltip>
                    </div>
                  </h4>
                </div>
              </div>
            </CardBody>

            {/* <img src={uniqueCall} alt="" style={{ filter: 'blur(2px)' }} />} */}
          </Card>
        </Col>

        <Col lg={4} md={6} xs={12}>
          <Card className="number-of-employee card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="avatar-sm flex-shrink-0">
                  <span
                    className="avatar-title  text-primary rounded-circle fs-3"
                    style={{ backgroundColor: "#068dc60f" }}
                  >
                    <i className="ri-pie-chart-2-line" style={{ color: '#068dc6' }}></i>
                  </span>
                </div>
                <div className="flex-grow-1 ms-3">
                  <p
                    className="text-capitalize fs-12  mb-1"
                    style={{ fontWeight: "900" }}
                  >
                    Number Of Employees
                  </p>

                  <h4 className="mb-0 fw-bold">
                    {" "}
                    <div>
                      {piechartData?.numberOfEmpLoyees}
                    </div>

                    <div className="dashboard-tooltip-eyesthree" data-bs-toggle="tooltip" data-bs-placement="top" id="DisabledAutoHideThird">
                      <span className="ri-information-line"></span>
                    </div>
                    <div>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpenThird}
                        autohide={false}
                        target="DisabledAutoHideThird"
                        toggle={toggleThird}
                      >
                        Represents the total number of active employees.
                      </Tooltip>
                    </div>
                  </h4>
                </div>
              </div>
            </CardBody>
            {/* <img src={employee} alt="" style={{ filter: 'blur(2px)' }} /> */}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DashboardEmployeeSummary;





export { DashboardEmployeeSummary }