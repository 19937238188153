import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store";
import { PersistGate } from 'redux-persist/integration/react';



const root = ReactDOM.createRoot(document.getElementById("root"));
const {store , persistor} = configureStore({});

root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
      <PersistGate loading={null} persistor={persistor} >
        <App />
      </PersistGate>
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);

reportWebVitals();
