import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import CommanDataTable from "../../Components/Common/DataTable";
import { APIClient } from "../../helpers/api_helper";
import { TOP_CALLER_API } from "../../helpers/url_helper";
import { DashboardDataTable } from '../../Components/Common/DashboardDataTbale';

const api = new APIClient();

const CallPerformance = ({ title, columns, sortKey, headerName }) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const getCurrentDate = () => {
    const now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0).getTime();
    const endOfDay = now.getTime();
    return [startOfDay, endOfDay];
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const [startTimestamp, endTimestamp] = getCurrentDate();
      const response = await api.get(`${TOP_CALLER_API}/${startTimestamp}/${endTimestamp}`);
      const sortedData = response.data.sort((a, b) => b[sortKey] - a[sortKey]);

      setData(sortedData);
      setTotalRows(sortedData.length);
    } catch (error) {
      setError("Error fetching data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, perPage]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={title} pageTitle="Call Management" />

          <Row>
            <Col lg={12}>
              <Card className="h-100">
                <div className='sn-metrics-tble-informtion'> <span>*</span> <i>{headerName}</i></div>
                <CardBody className="pt-0">
                  <div className="custom-datatable">
                    <Row className="g-3 mt-0">
                      <Col
                        lg={12}
                        className="sn-tble-call-archive-mainn sn-team-number--employyes-btn sn-tble-bottom-scroll-main sn-total-calltype-btn-status sn-employee-managment-tble-detail"
                        style={{ textAlign: "center", overflow: "scroll" }}
                      >
                        <DashboardDataTable
                          currentPage={currentPage}
                          column={columns}
                          // fixedHeaderScrollHeights="480px"
                          tableData={data}
                        />
                      </Col>
                    </Row>
                  </div>
                  {/* <CommanDataTable
                    column={columns}
                    tableData={data}
                    handlePerRowsChange={(newPerPage, page) => {
                      setPerPage(newPerPage);
                      setCurrentPage(page);
                    }}
                    totalRows={totalRows}
                    currentPage={currentPage}
                    isLoading={loading}
                    handlePageChange={page => setCurrentPage(page)}
                    inputClass={  (data && data.length) ? 'd-none' : 'd-none'}
                  /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CallPerformance;
