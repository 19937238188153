import React, {useRef, useState} from "react";
import {
    Col,
    FormFeedback,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Form,
    Input,
    ModalFooter,
    Button,
    Spinner,
} from "reactstrap";
import * as Yup from "yup";
import {useFormik} from "formik";
import moment from "moment";
import {EMPLOYEE_UPDATE_EMPLOYEE, UPDATE_EMPLOYEE} from "../../../helpers/url_helper";
import {APIClient} from "../../../helpers/api_helper";
import Toast from "../../../Components/Common/Toast";
import {ToastContainer} from "react-toastify";
import {HandleErrorMessage} from "../../../Components/Common/HandleErrorMessage";
import {FlatDatepickr} from "../../../Components/Common/Flatpicker";

const api = new APIClient();

const UpdateUser = ({
    employeeId,
    mobileNumber,
    username,
    email,
    loginType,
    updatedPermission,
    modal_grid,
    GetCreateData,
    setmodal_grid,
    dateOfJoining,
    designationRole,
}) => {
    console.log(employeeId, "employeeId");

    const flatpickrRef = useRef(null);
    const isEmailDisabled = true;
    let selectedDate = moment(dateOfJoining).format("YYYY-MM-DD");
    const phoneNumberRules = /^[1-9]\d{9}$/;
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            userName: username || "",
            email: email || "",
            mobileNumber: mobileNumber || "",
            dateOfJoining: selectedDate || "",
            designation: designationRole || "",
        },
        validationSchema: Yup.object({
            userName: Yup.string().required("Please Enter Employee Name"),
            mobileNumber: Yup.string()
            .matches(phoneNumberRules, {message: "Please Enter 10 Digit Mobile Number"})
            .required("Please Enter Your Mobile Number"),
            dateOfJoining: Yup.string().required(" Please Enter Your Date Of Joining"),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            try {
                let data = {
                    _id: employeeId,
                };
                if (values.email !== email) {
                    data.email = values.email;
                }
                if (values.userName !== username) {
                    data.fullName = values.userName;
                }
                if (values.mobileNumber !== mobileNumber) {
                    data.mobileNumber = values.mobileNumber;
                }
                if (values.dateOfJoining !== selectedDate) {
                    data.dateOfJoining = values.dateOfJoining;
                }
                if (values.designation !== designationRole) {
                    data.jobTitle = values.designation;
                }
                let response;
                if (loginType === "employee" && updatedPermission) {
                    response = await api.patch(`${EMPLOYEE_UPDATE_EMPLOYEE}`, data);
                } else if (loginType === "organization") {
                    response = await api.patch(`${UPDATE_EMPLOYEE}`, data);
                }
                if (response?.success === true) {
                    Toast(response?.message, "success");
                    setTimeout(() => {
                        GetCreateData();
                        // formik.resetForm();
                        setmodal_grid(false);
                    }, 2000);
                }
            } catch (error) {
                HandleErrorMessage(error);
            } finally {
                setLoading(false);
            }
        },
    });

    const toggle = () => {
        if (modal_grid) {
            formik.resetForm();
        }
        setmodal_grid(!modal_grid);
    };

    return (
        <Row>
            <ToastContainer />
            <Modal
                isOpen={modal_grid}
                toggle={() => {
                    toggle();
                }}
            >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={toggle}>
                    Update Employee{" "}
                </ModalHeader>
                <Form onSubmit={formik.handleSubmit} className="needs-validation">
                    <ModalBody>
                        <Row>
                            <Col sm={6}>
                                <Label htmlFor="username" className="form-label">
                                    Name<span className="text-danger">*</span>
                                </Label>
                                {/* <Input
                                    name="userName"
                                    type="text"
                                    placeholder="Enter Employee Name"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.userName}
                                    invalid={
                                        formik.touched.userName && !!formik.errors.userName
                                    }
                                />
                                {formik.touched.userName && formik.errors.userName ? (
                                    <FormFeedback type="invalid"><div>{formik.errors.userName}</div></FormFeedback>
                                ) : null} */}

                                <Input
                                    name="userName"
                                    type="text"
                                    placeholder="Enter Employee Name"
                                    onChange={(e) => {
                                        const alphabeticValue = e.target.value.replace(/[^a-zA-Z -]/g, "");
                                        formik.setFieldValue("userName", alphabeticValue); 
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.userName}
                                    invalid={formik.touched.userName && !!formik.errors.userName}
                                />
                                {formik.touched.userName && formik.errors.userName ? (
                                    <FormFeedback type="invalid">
                                        <div>{formik.errors.userName}</div>
                                    </FormFeedback>
                                ) : null}
                                

                            </Col>
                            <Col sm={6}>
                                <Label htmlFor="useremail" className="form-label">
                                    Email
                                </Label>
                                <Input
                                    name="email"
                                    type="email"
                                    placeholder="Enter Email "
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    invalid={formik.touched.email && formik.errors.email ? true : false}
                                    disabled={isEmailDisabled}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <FormFeedback type="invalid">
                                        <div>{formik.errors.email}</div>
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col sm={6}>
                                <Label htmlFor=" ContactNumber" className="form-label">
                                    Contact Number<span className="text-danger">*</span>
                                </Label>
                                <Input
                                    type="tel"
                                    name="mobileNumber"
                                    placeholder="Enter Contact No..."
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.mobileNumber}
                                    invalid={formik.touched.mobileNumber && formik.errors.mobileNumber ? true : false}
                                />
                                {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                                    <FormFeedback type="invalid">
                                        <div>{formik.errors.mobileNumber}</div>
                                    </FormFeedback>
                                ) : null}
                            </Col>

                            <Col sm={6}>
                                <Label htmlFor="dateOfJoining" className="form-label">
                                    Joining Date<span className="text-danger">*</span>
                                </Label>
                                <FlatDatepickr
                                    name="dateOfJoining"
                                    value={formik.values.dateOfJoining}
                                    onChange={formik.setFieldValue}
                                    error={formik.errors.dateOfJoining}
                                    touched={formik.touched.dateOfJoining}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col sm={6}>
                                <Label htmlFor="designation" className="form-label">
                                    Designation
                                </Label>
                                <Input
                                    name="designation"
                                    type="text"
                                    placeholder="Enter designation"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.designation}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ""); // Remove non-letter and non-space characters
                                    }}
                                    invalid={formik.touched.designation && formik.errors.designation ? true : false}
                                />
                                {formik.touched.designation && formik.errors.designation ? (
                                    <FormFeedback type="invalid">
                                        <div>{formik.errors.designation}</div>
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <Button
                                d="add-btn"
                                disabled={loading ? true : false}
                                className="btn btn-danger w-100"
                                type="submit"
                            >
                                {loading ? (
                                    <Spinner size="sm" className="me-2">
                                        {" "}
                                        Loading...{" "}
                                    </Spinner>
                                ) : null}
                                Update
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </Row>
    );
};

export default UpdateUser;
