import { APIClient } from "../../helpers/api_helper";
import { AEDINR } from "../../helpers/url_helper";

// apiUtils.js
const api = new APIClient(); // Adjust the import based on your api setup

export const getCurrencyExchangeRate = async (setStateCallback) => {
    try {
        const response = await api.create(`${AEDINR}`);
        if (response.success === true) {
            setStateCallback(response?.data?.rates?.INR);
        }
    } catch (error) {
        console.error("API call error:", error);
    }
};
