import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap';
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";
import SearchOption from '../Components/Common/SearchOption';
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';
import LightDark from '../Components/Common/LightDark';
import { changeSidebarVisibility } from '../store/actions';
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import { APIClient } from '../helpers/api_helper';
import { useProfileData } from '../Components/Hooks/UserHooks';
const api = new APIClient();


const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
    const loginType = JSON.parse(localStorage.getItem("logintype"))
    const { profileData } = useProfileData()
    const dispatch = useDispatch();
    const [search, setSearch] = useState(false);
    const [remainingDays, setRemainingDays] = useState(null);

    const toogleSearch = () => {
        setSearch(!search);
    };

    const { sidebarVisibilitytype } = useSelector(state => ({
        sidebarVisibilitytype: state.Layout.sidebarVisibilitytype
    }));


    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;
        dispatch(changeSidebarVisibility("show"));
        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle('open');
        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }
        //For collapse vertical and semibox menu
        if (sidebarVisibilitytype === "show" && (document.documentElement.getAttribute('data-layout') === "vertical" || document.documentElement.getAttribute('data-layout') === "semibox")) {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }
        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };



    useEffect(() => {
        const calculateRemainingDays = () => {
            if (profileData?.subscriptionEndDate && profileData?.subscriptionStartDate) {
                const endDate = new Date(profileData?.subscriptionEndDate);
                const startDate = new Date(profileData?.subscriptionStartDate);
                const currentDate = new Date();

                const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
                const remainingDays = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24))
                setRemainingDays(remainingDays > 0 ? remainingDays : 0);
                const interval = setInterval(() => {
                    const newCurrentDate = new Date();
                    const newRemainingDays = Math.ceil((endDate - newCurrentDate) / (1000 * 60 * 60 * 24));
                    setRemainingDays(newRemainingDays);
                }, 1000 * 60 * 60 * 24);

                return () => clearInterval(interval);
            } else {
                setRemainingDays(null);
            }
        };

        calculateRemainingDays();
    }, [profileData]);

    
    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box horizontal-logo">
                                <Link to={loginType === 'SuperAdmin' ? "/admin-dashboard" : "/"} className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </Link>

                                <Link to={loginType === 'SuperAdmin' ? "/admin-dashboard" : "/"} className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="17" />
                                    </span>
                                </Link>
                            </div>

                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>
                            <SearchOption />
                        </div>

                        <div className="d-flex align-items-center">
                            {profileData?.selectedPlan === 'free' ?
                                <><div className='sn-free-trail-title-top'>
                                    <h3>
                                        <i className='ri-alert-line'></i>&nbsp;
                                        {remainingDays === 0
                                            ? 'Free Trial has expired'
                                            : `Your Free Trial ends in ${profileData?.selectedPlanDetails.planName === 'Free Plan' ? remainingDays : ''} days!`}
                                    </h3>
                                </div></> : null}
                            <Dropdown isOpen={search} toggle={toogleSearch} className="d-md-none topbar-head-dropdown header-item">
                                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                                    <i className="bx bx-search fs-22"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                    <Form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search..."
                                                    aria-label="Recipient's username" />

                                                <button className="btn btn-primary" type="submit"><i
                                                    className="mdi mdi-magnify"></i></button>
                                            </div>
                                        </div>
                                    </Form>
                                </DropdownMenu>
                            </Dropdown>

                            <FullScreenDropdown />

                            {/* Dark/Light Mode set */}
                            {loginType === 'SuperAdmin' ? '' : <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                                users={profileData}
                            />}

                            {/* NotificationDropdown */}
                            {/* <NotificationDropdown /> */}

                            {/* ProfileDropdown */}
                            <ProfileDropdown users={profileData} />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

Header.propTypes = {
    profileData: PropTypes.shape({
        selectedPlanDetails: PropTypes.object
    })
};
export default Header;