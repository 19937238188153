import { call, put, takeEvery } from "redux-saga/effects";
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { postEmployeeFakeLogin, postFakeLogin, postSuperAdminLogin } from "../../../helpers/fakebackend_helper";


function* loginUser({ payload: { user, history, loginType } }) {
  try {
    let response;
    if (process.env.REACT_APP_API_URL) {
      if (loginType === 'employee') {
        response = yield call(postEmployeeFakeLogin, {
          email: user.email,
          password: user.password,
        });
      } else if (loginType === 'organization') {
        response = yield call(postFakeLogin, {
          email: user.email,
          password: user.password,
        });
      }
      else if (loginType === 'SuperAdmin') {
        response = yield call(postSuperAdminLogin, {
          email: user.email,
          password: user.password,
        });
      }
      const token = response?.data?.token;
      if (response.message === "success") {
        localStorage.setItem("authUser", JSON.stringify(token));
        localStorage.setItem('permission', JSON.stringify(response.data.permissions ? response.data.permissions : []));
        history("/dashboard");
        yield put(loginSuccess({ token }));
      } else {
        yield put(apiError(response));
      }
    }
    else {
      throw new Error('Invalid login type');
    }

  } catch (error) {
    yield put(apiError(error));
  }

}


function* logoutUser({ payload: { history } }) {
  try {
    const loginType = localStorage.getItem('logintype');
    if (loginType === 'SuperAdmin') {
      history('/admin-login');
      localStorage.removeItem('authUser');
      localStorage.removeItem('logintype');
      localStorage.removeItem('permission');
      localStorage.removeItem('selectedPermissions');
      localStorage.removeItem('delgetRolData');
      localStorage.removeItem('getRolData');
      localStorage.removeItem('selectedKeys')
    } else {
      history('/login');
      localStorage.removeItem('authUser');
      localStorage.removeItem('logintype');
      localStorage.removeItem('permission');
      localStorage.removeItem('selectedPermissions');
      localStorage.removeItem('delgetRolData');
      localStorage.removeItem('getRolData');
      localStorage.removeItem('selectedKeys')
    }
    yield put(logoutUserSuccess());

  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* checkTokenSaga() {
  const token = localStorage.getItem('authUser');
  if (token) {
    yield put(loginSuccess(token));
  } else {
    yield put(logoutUserSuccess());
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield call(checkTokenSaga);
}

export default authSaga;
