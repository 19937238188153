import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

// Utility function to get Excel column letter from an index
export const getExcelColumnLetter = (colIndex) => {
  let letter = "";
  while (colIndex >= 0) {
    letter = String.fromCharCode((colIndex % 26) + 65) + letter;
    colIndex = Math.floor(colIndex / 26) - 1;
  }
  return letter;
};
// Function to export data to Excel
export const exportToExcel = async (
  data,
  headers,
  filename = "ExportedData.xlsx",
  dataStructure = ""
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data Report");

  // Set columns dynamically based on headers
  worksheet.columns = headers.map((header) => ({
    header: header.label,
    key: header.key,
    width: header.label.length + 25,
  }));

  data.forEach((item) => {
    if (dataStructure === "neverAttended" && item.time) {
      // Process each time entry as a separate row for "NeverAttended" data structure
      item.time.forEach((callTime) => {
        const row = {};
        headers.forEach((header) => {
          if (header.key === 'date') {
            row[header.key] = moment(callTime.date).format("DD MMM YYYY");
          } else if (header.key === 'time') {
            row[header.key] = moment(callTime.date).format("hh:mm A");
          } else if (header.key === 'type') {
            row[header.key] = callTime.type;
          } else {
            row[header.key] = item[header.key];
          }
        });
        worksheet.addRow(row);
      });
    }
    else {
      // Standard row processing for data not matching "NeverAttended"
      const row = {};
      headers.forEach((header) => {
        row[header.key] = item[header.key];
      });
      worksheet.addRow(row);
    }
  });

  // Apply header styling
  worksheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "f17171" },
    };
    cell.alignment = { vertical: "middle", horizontal: "center" };
  });
  worksheet.getRow(1).height = 30;

  // Apply alternating row colors
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1 && rowNumber % 2 !== 0) {
      row.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "fff2f2" },
        };
      });
    }
    row.alignment = { vertical: "middle", horizontal: "center" };
  });

  // Add autofilter
  //   worksheet.autoFilter = { from: "A1", to: worksheet.columns[worksheet.columns.length - 1].header };

  const lastColumnLetter = getExcelColumnLetter(headers.length - 1);
  worksheet.autoFilter = {
    from: "A1",
    to: `${lastColumnLetter}1`,
  };

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, filename);
};


export const employeeExportToExcel = async ({ data }) => {
  const filename = "Employee Report.xlsx"
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data Report");

  // Merge cells for group headers
  worksheet.mergeCells("A1:A2"); // SN
  worksheet.mergeCells("B1:B2"); // Date
  worksheet.mergeCells("C1:C2"); // Total Calls
  worksheet.mergeCells("D1:D2"); // Total Duration
  worksheet.mergeCells("E1:E2"); // Unique Calls
  worksheet.mergeCells("F1:F2"); // Connected Calls
  worksheet.mergeCells("G1:I1"); // Incoming Group
  worksheet.mergeCells("J1:L1"); // Outgoing Group

  // First header row with group titles
  worksheet.getRow(1).values = [
    "SN", "Date", "Total Calls", "Total Duration", "Unique Calls", "Connected Calls", "",
    "Incoming", "", "", "Outgoing", "", "Missed", "Rejected", "Never Attended", "Not Picked Up By Client"
  ];
  // Apply center alignment for merged cells ("Incoming", "Outgoing")
  ["G1", "J1"].forEach((cellRef) => {
    const cell = worksheet.getCell(cellRef);
    cell.alignment = { vertical: "middle", horizontal: "center" };  // Center alignment
  });


  // Second header row for sub-columns
  worksheet.getRow(2).values = [
    "", "", "", "", "", "",
    "Total Call", "Connected Call", "Total Duration",
    "Total Call", "Connected Call", "Total Duration",
    ""
  ];

  // Apply center alignment and background color for sub-columns
  ["G2", "H2", "I2"]?.forEach((cellRef) => {
    const cell = worksheet.getCell(cellRef);

    // Apply center alignment for the sub-columns
    cell.alignment = { vertical: "middle", horizontal: "center" };

    // Apply background color for the sub-columns
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "68b274" }  // Red background color (adjust if needed)
    };

    // Optional: Apply bold font for better visibility
    cell.font = { bold: true, color: { argb: "FFFFFFFF" } };  // White text
  });

  ["J2", "K2", "L2"].forEach((cellRef) => {
    const cell = worksheet.getCell(cellRef);

    // Apply center alignment for the sub-columns
    cell.alignment = { vertical: "middle", horizontal: "center" };

    // Apply background color for the sub-columns
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "3abfdd" }  // Red background color (adjust if needed)
    };

    // Optional: Apply bold font for better visibility
    cell.font = { bold: true, color: { argb: "FFFFFFFF" } };  // White text
  });


  // Apply center alignment for the merged cells
  const incomingHeader = worksheet.getCell("G1");
  const outgoingHeader = worksheet.getCell("J1");

  // Align both vertically and horizontally to center
  incomingHeader.alignment = { vertical: "middle", horizontal: "center" };
  outgoingHeader.alignment = { vertical: "middle", horizontal: "center" };

  // Apply center alignment for sub-columns as well
  ["G2", "H2", "I2", "J2", "K2", "L2"].forEach(cellRef => {
    worksheet.getCell(cellRef).alignment = { vertical: "middle", horizontal: "center" };
  });


  // Adjust column widths
  worksheet.columns = [
    { key: "sn", width: 25 },
    { key: "date", width: 25 },
    { key: "totalCalls", width: 25 },
    { key: "totalDuration", width: 25 },
    { key: "uniqueCalls", width: 25 },
    { key: "connectedCalls", width: 25 },
    { key: "incomingTotalCall", width: 25 },
    { key: "incomingConnectedCall", width: 25 },
    { key: "incomingTotalDuration", width: 25 },
    { key: "outgoingTotalCall", width: 25 },
    { key: "outgoingConnectedCall", width: 25 },
    { key: "outgoingTotalDuration", width: 25 },
    { key: "missed", width: 25 },
    { key: "rejected", width: 25 },
    { key: "naverAttend", width: 25 },
    { key: "notPickup", width: 25 },
  ];

  // Apply header styling
  worksheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "f17171" },
    };
    cell.alignment = { vertical: "middle", horizontal: "center" }; // Center text in header
  });

  worksheet.getRow(2).eachCell((cell) => {
    cell.font = { bold: true };
    cell.alignment = { vertical: "middle", horizontal: "center" }; // Center text in sub-headers
  });

  worksheet.getRow(1).height = 30;

  data?.forEach((item, index) => {
    const row = worksheet.addRow({
      sn: item.srNo,
      date: item.date,
      totalCalls: item.tCalls,
      totalDuration: item.tDuration,
      uniqueCalls: item.uniqueClient,
      connectedCalls: item.connectedCalls,
      incomingTotalCall: item.inTotalCall,
      incomingConnectedCall: item.inConnectedCall,
      incomingTotalDuration: item.indurationCall,
      outgoingTotalCall: item.outTotalCall,
      outgoingConnectedCall: item.outTotalConnected,
      outgoingTotalDuration: item.outTotalduration,
      missed: item?.missed,
      rejected: item?.rejected,
      naverAttend: item?.naverAttend,
      notPickup: item?.notPickup
    });

    // Center align text in the data rows
    row.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Center text
    });

    // Apply alternating row colors
    if (index % 2 !== 0) {
      row.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "fff2f2" },
        };
      });
    }
  });


  // Set autofilter for the columns
  const lastColumnLetter = getExcelColumnLetter(worksheet.columns.length - 1);
  worksheet.autoFilter = {
    from: "A1",
    to: `${lastColumnLetter}1`,
  };

  // Write and save the Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, filename);
};

export const employeeManagement = async ({ employeeData }) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("User Management");

  // Define header columns
  worksheet.columns = [
    { header: "SN.", key: "serialNumber", width: "SN".length + 20 },
    { header: "Employee Name", key: "fullName", width: "Employee Name".length + 20 },
    { header: "Employee Number", key: "mobileNumber", width: "Employee Number".length + 20 },
    { header: "Email", key: "email", width: "Email".length + 50 },
    { header: "Designation", key: "jobTitle", width: "Designation".length + 20 },
    { header: "Role Name", key: "role", width: "Role Name".length + 20 },
    { header: "Team", key: "team", width: "Team".length + 20 },
    { header: "Registered Date", key: "date", width: "Registered Date".length + 20 },
    { header: "Device Name", key: "deviceName", width: "Device Name".length + 20 },
    { header: "Status", key: "status", width: "Status".length + 20 },
  ];

  // Add data rows
  employeeData.forEach((item, index) => {
    const teams = item.team || []; // Default to empty array if no teams
    const roles = item.role || []; // Default to empty array if no roles

    if (teams.length === 0 && roles.length === 0) {
      // If no team and no role, add a single row with "No Team" and "No Role"
      worksheet.addRow({
        serialNumber: item.serialNumber,
        name: item.name,
        fullName: item.fullName,
        mobileNumber: item.mobileNumber || "-",
        email: item.email || "-",
        jobTitle: item.jobTitle || "-",
        role: "-", // Placeholder for no role
        team: "-", // Placeholder for no team
        date: item.date || "-",
        deviceName: item.deviceName || "-",
        status: item.status || "-"
      });
    } else if (teams.length === 0) {
      // If no team but there are roles, add a row for each role with "No Team"
      roles.forEach((role) => {
        worksheet.addRow({
          serialNumber: item.serialNumber,
          name: item.name,
          fullName: item.fullName,
          mobileNumber: item.mobileNumber || "-",
          email: item.email || "-",
          jobTitle: item.jobTitle || "-",
          role: role, // Show actual role name
          team: "-", // Placeholder for no team
          date: item.date || "-",
          deviceName: item.deviceName || "-",
          status: item.status || "-"
        });
      });
    } else if (roles.length === 0) {
      // If no role but there are teams, add a row for each team with "No Role"
      teams.forEach((team) => {
        worksheet.addRow({
          serialNumber: item.serialNumber,
          name: item.name,
          fullName: item.fullName,
          mobileNumber: item.mobileNumber || "-",
          email: item.email || "-",
          jobTitle: item.jobTitle || "-",
          role: "-", // Placeholder for no role
          team: team, // Show actual team name
          date: item.date || "-",
          deviceName: item.deviceName || "-",
          status: item.status || "-"
        });
      });
    } else {
      // If there are both teams and roles, create a row for each team-role combination
      teams.forEach((team) => {
        roles.forEach((role) => {
          worksheet.addRow({
            serialNumber: item.serialNumber,
            name: item.name,
            fullName: item.fullName,
            mobileNumber: item.mobileNumber || "-",
            email: item.email || "-",
            jobTitle: item.jobTitle || "-",
            role: role, // Show actual role name
            team: team, // Show actual team name
            date: item.date || "-",
            deviceName: item.deviceName || "-",
            status: item.status || "-"
          });
        });
      });
    }
  });


  // Apply header styling (background color and font color)
  worksheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true, color: { argb: "FFFFFFFF" } }; // White font
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "f17171" }, // Red background
    };
    cell.alignment = { vertical: "middle", horizontal: "center" }; // Center-align
  });

  // Adjust header row height
  worksheet.getRow(1).height = 30;

  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      // Apply color for odd rows (1st, 3rd, 5th...)
      if (rowNumber % 2 !== 0) {
        row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "fff2f2" }, // Light grey for alternating rows
          };
        });
      }

      // Apply alignment for all data rows
      row.alignment = { vertical: "middle", horizontal: "center" };
    }
  });

  // Add autofilter to the worksheet
  worksheet.autoFilter = {
    from: "A1", // The starting cell of the autofilter (first row)
    to: "I1", // The ending cell of the autofilter (last header cell)
  };

  // Export the Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, "UserManagement.xlsx");
}

// Function to export data to PDF
export const exportToPDF = (data, headers, filename = "ExportedData.pdf", title, dataStructure = "") => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = doc.getTextWidth(title);
  const textX = (pageWidth - textWidth) / 2;

  doc.text(title, textX, 10);

  // Map headers dynamically for PDF export
  const headerLabels = headers.map((header) => header.label);
  const tableData = [];

  // Build the table data for "NeverAttended" structure
  data.forEach((item) => {
    if (dataStructure === "neverAttended" && item.time) {
      // Process each time entry as a separate row
      item.time.forEach((callTime) => {
        const row = headers.map((header) => {
          if (header.key === 'time') {
            return moment(callTime.date).format("hh:mm A"); // Format time as "hh:mm A"
          } else if (header.key === 'date') {
            return moment(callTime.date).format("DD MMM YYYY"); // Format date as "DD MMM YYYY"
          } else if (header.key === 'type') {
            return callTime.type; // Call type
          } else {
            return item[header.key]; // Other fields from the item
          }
        });
        tableData.push(row);
      });
    } else {
      // Standard row processing for other data structures
      const row = headers.map((header) => item[header.key]);
      tableData.push(row);
    }
  });
  doc.autoTable({
    head: [headerLabels],
    body: tableData,
    startY: 20,
    styles: { fontSize: 8 },
    headStyles: { fillColor: [255, 71, 71] },
    alternateRowStyles: { fillColor: [245, 245, 245] },
  });

  doc.save(filename);
};

