
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { logoutUser } from "./actions";

// Create the persist configuration
const persistConfig = {
  key: "root",  
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);

  window.addEventListener("storage", (event) => {
    if (event.key === "token" && !event.newValue) {
      store.dispatch(logoutUser());
    }
  });

  return { store, persistor }; // Return both store and persistor
}