import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from "react-router-dom";
// Sales Ninja
import SnLanding from '../pages/Landing/SaleNinja'
//Authentication
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import ForgetNewPassword from "../pages/Authentication/ForgetNewPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ContactUs from "../pages/Landing/SaleNinja/ContactUs/ContactUs";
//profile 
import UserProfile from "../pages/Authentication/user-profile";
// subscripe
import PlanPackages from "../pages/PlanPricing/PlanPackages";
//all Report 
import EmployeeReport from "../pages/Report/EmployeeReport";
import ClientReport from "../pages/Report/ClientReport";
import NeverAttendedCall from "../pages/Report/NeverAttendedCall";
import NeverPickepReport from "../pages/Report/NeverPickepReport";
import CallHistoryReport from "../pages/Report/CallHistoryReport";
import RoleManagement from "../pages/Management/RoleManagement/RoleManagement";
import UserManagement from "../pages/Management/UserManagement/UserManagement";
import AppVersion from "../pages/Management/UserManagement/AppVersion";
import CallRecordingReport from "../pages/Report/CallRecordingReport";
import CallArchivesReport from "../pages/Report/CallArchivesReport";
import SuccessMsg from "../pages/AuthenticationInner/SuccessMessage/SuccessMsg";
import DashboardCallCrm from "../pages/DashboardCallCrm";
import InvoiceList from "../pages/Invoices/InvoiceList";
import VerifiedSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/VerifiedSuccessMsg";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import AddonPlan from "../pages/PlanPricing/AddonPlan";
import FuturePlan from "../pages/PlanPricing/FuturePlan";
import SubscriptionPlan from "../pages/PlanPricing/SubscriptionPlan";
import PaymentSuccessMsg from "../Components/Common/PaymentSuccessMsg";
import PaymentFailureMsg from "../Components/Common/PaymentFailureMsg";
import AlreadyVerifiedSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/AlreadyVerifiedSuccessMsg";
import NinjaLogo from "../pages/Logo/NinjaLogo";
import Twitter from "../pages/Logo/twitter";
import Linkedin from "../pages/Logo/Linkedin";
import Facebook from "../pages/Logo/Facebook";
import Insta from "../pages/Logo/insta";
import ResetPassword from "../pages/Logo/ResetPassword";
import Team from "../pages/team/Team";
import SubExpired from "../Components/Common/subExpired";
import ThemeProfile from "../pages/Logo/themeProfile";
import ForgetEmployeeNewPassword from "../pages/Authentication/ForgetEmployeePassword";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";
import ErrorFiveHundred from "../pages/AuthenticationInner/Errors/Error500";
import SalesNinjaLogo from "../pages/Logo/salesNinjaLogo";
import SuperAdmin from "../pages/Authentication/Super-Admin";
import SuperAdminDashboard from "../pages/SuperAdminDashboard";
import AgentTable from "../pages/AgentPerformance/AgentPerformanceReport";
import SetPerformance from "../pages/AgentPerformance/SetPerformanceReport";
import ViewLeaderboard from "../pages/AgentPerformance/ViewLeaderboard";
import { TopCallersReport, TopConnectedReport, TopDialersReport, TopDurationReport } from "../pages/Report/AllTopReport";



const authProtectedRoutes = [

  //  plan and Pricing
  { path: "/expired-plan", component: <SubExpired /> },
  { path: "/admin-dashboard", component: <SuperAdminDashboard /> },
  { path: "/dashboard", component: <DashboardCallCrm /> },
  //  plan and Pricing
  { path: "/planPackage", component: <PlanPackages /> },
  //Invoices
  { path: "/invoices", component: <InvoiceList /> },
  { path: "/subscription-plan", component: <SubscriptionPlan /> },
  { path: "/addon-plan", component: <AddonPlan /> },
  { path: "/future-plan", component: <FuturePlan /> },
  //team list
  { path: "/team-Management", component: <Team /> },
  //employee list
  { path: "/employee-Management", component: <UserManagement /> },
  { path: "/app-version", component: <AppVersion />  },
  //employee list
  { path: "/role-management", component: <RoleManagement /> },
  // report 
  { path: "/call-report", component: <CallHistoryReport /> },
  { path: "/employee-report", component: <EmployeeReport /> },
  { path: "/client-report", component: <ClientReport /> },
  { path: "/neverAttended-report", component: <NeverAttendedCall /> },
  { path: "/notPickeped-report", component: <NeverPickepReport /> },
  { path: "/topCallers-report", component: <TopCallersReport /> },
  { path: "/topDialers-report", component: <TopDialersReport/> },
  {path: "/topCallDuration-report", component: <TopDurationReport />},
  {path: "/topConnected-report", component: <TopConnectedReport />},
  

  //  call Recording
  { path: "/call-recordings", component: <CallRecordingReport /> },
  { path: "/call-archives", component: <CallArchivesReport /> },
  //User Profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/profile/update/:update_type", component: <UserProfile /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

    // Agent Performance Report
    { path: "/agent-performance", component: <AgentTable /> },
    { path: "/set-performance", component: <SetPerformance /> },
    { path: "/view-leaderboard", component: <ViewLeaderboard /> },
];


const publicRoutes = [
  { path: "/", component: <Login /> },
  { path: "/admin-login", component: <SuperAdmin /> },
  { path: "/contact", component: <ContactUs /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/organization-create-password", component: <ForgetNewPassword /> },
  { path: "/employee-create-password", component: <ForgetEmployeeNewPassword /> },

  { path: "/register", component: <Register /> },
  { path: "/auth-logout", component: <BasicLogout /> },
  //AuthenticationInner pages
  { path: "/auth-success-msg", component: <SuccessMsg /> },
  // payment routes 
  { path: "/payment-success", component: <PaymentSuccessMsg /> },
  { path: "/payment-failure", component: <PaymentFailureMsg /> },
  { path: "/verify-organization", component: <VerifiedSuccessMsg /> },
  { path: "/already-verify-organization", component: <AlreadyVerifiedSuccessMsg /> },
  // logo and twitter facebook insta,

  { path: "/logo-theme", component: <ThemeProfile /> },
  { path: "/logo-sales-ninja", component: <NinjaLogo /> },
  { path: "/sales-ninja-email-logo", component: <SalesNinjaLogo /> },
  { path: "/logo-twitter", component: <Twitter /> },
  { path: "/logo-facebook", component: <Facebook /> },
  { path: "/logo-instagram", component: <Insta /> },
  { path: "/logo-linkedin", component: <Linkedin /> },
  { path: "/logo-resetpass", component: <ResetPassword /> },

  { path: "/server-error", component: <ErrorFiveHundred /> },
  { path: "/auth-offline", component: <Offlinepage /> },
];



export { authProtectedRoutes, publicRoutes };