import React, { useEffect, useState } from "react";
import Pagination from "https://cdn.skypack.dev/rc-pagination@3.1.15";

import { Table, Container, Row, Col, ToastContainer } from "react-bootstrap";

// Component
import BreadCrumb from "../../Components/Common/BreadCrumb";

import { Card } from "reactstrap";



const AgentTable = () => {
  // const TableData = [
  //   {
  //     Serial: 1,
  //     name: "Mahendra Sankhala ASM",
  //     unique_client: 57,
  //     call_duration: "08:30:05",
  //     average_call_duration: "00:02:50",
  //     total_call: 321,
  //     incoming_call: 128,
  //     outgoing_call: 87,
  //     missed_call: 87,
  //     rejected_call: 10,
  //     not_picked: 36,
  //   },
  //   {
  //     Serial: 2,
  //     name: "Pravanshu ASM",
  //     unique_client: 57,
  //     call_duration: "08:30:05",
  //     average_call_duration: "00:02:50",
  //     total_call: 321,
  //     incoming_call: 128,
  //     outgoing_call: 87,
  //     missed_call: 87,
  //     rejected_call: 10,
  //     not_picked: 36,
  //   },
  //   {
  //     Serial: 3,
  //     name: "Bhawarth Malvia ASM",
  //     unique_client: 57,
  //     call_duration: "08:30:05",
  //     average_call_duration: "00:02:50",
  //     total_call: 321,
  //     incoming_call: 128,
  //     outgoing_call: 87,
  //     missed_call: 87,
  //     rejected_call: 10,
  //     not_picked: 36,
  //   },
  //   {
  //     Serial: 4,
  //     name: "Tanya ASM",
  //     unique_client: 57,
  //     call_duration: "08:30:05",
  //     average_call_duration: "00:02:50",
  //     total_call: 321,
  //     incoming_call: 128,
  //     outgoing_call: 87,
  //     missed_call: 87,
  //     rejected_call: 10,
  //     not_picked: 36,
  //   },

  //   {
  //     Serial: 5,
  //     name: "Tanya ASM",
  //     unique_client: 57,
  //     call_duration: "08:30:05",
  //     average_call_duration: "00:02:50",
  //     total_call: 321,
  //     incoming_call: 128,
  //     outgoing_call: 87,
  //     missed_call: 87,
  //     rejected_call: 10,
  //     not_picked: 36,
  //   },
  //   {
  //     Serial: 6,
  //     name: "Tanya ASM",
  //     unique_client: 57,
  //     call_duration: "08:30:05",
  //     average_call_duration: "00:02:50",
  //     total_call: 321,
  //     incoming_call: 128,
  //     outgoing_call: 87,
  //     missed_call: 87,
  //     rejected_call: 10,
  //     not_picked: 36,
  //   },
  // ];

  const Tablethead = [
    { title: "Sr.No" },
    { title: "Agent Name" },
    { title: "Unique Client" },
    { title: "Call Duration" },
    { title: "Average Call Duration" },
    { title: "Total Call" },
    { title: "Total Incoming Call" },
    { title: "Total Outgoing Call" },
    { title: "Total Missed Call" },
    { title: "Total Rejected Call" },
    { title: "Total Not Picked Up By Client" },
  ];

 
  const [TableData, setTableData] = useState(null);
  const [error, setError] = useState(null);
  const [perPage, setPerPage] = useState(5);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders?.append("Content-Type", "application/json");
    myHeaders?.append(
      "Authorization",
      "Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7Il9pZCI6IjY2Mjc2OWE2YjI2MDVjNzdiYWQ3OGJiYiIsIm9yZ2FuaXphdGlvbk5hbWUiOiJSZWR2aXNpb24iLCJvcmdhbml6YXRpb25JZCI6IlJFRC0zMDY0LTY5NzEiLCJvcmdhbml6YXRpb25TdHJlbmd0aCI6MTA0LCJ2ZXJpZmllZCI6dHJ1ZSwic3RhdHVzIjoiQWN0aXZlIiwidHlwZSI6Im9yZ2FuaXphdGlvbiJ9LCJpYXQiOjE3MjY2NjQxODh9.bGC18Uc7VdBv3_-BV2-nTwqj8od8bAFXW1JywzKyTBGzyexFM3g2H3aKCj4cxtTrfQAlzfo-UtO-Et4Y6gaJ2Q"
    );

    const raw = JSON.stringify({
      startDate: "2024-01-01T18:00:00.000Z",
      endDate: "2024-08-10T18:30:00.000Z",
      employeeIds: [
        "6672c5bcf229f038ef54ab3e",
        "667fb13ac3d35fe34783b567",
        "6672c111446f11a4b2d9a5a6",
        "667d4eb1e6681ee46a3fcd0c",
        "66681e563e70cff752657a58",
        "66827a861e9e79712916f966",
        "6672b346446f11a4b2d98674",
        "66a1f40ca796c5346e589f6b",
      ],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://service.salesninjacrm.com/call-reports/get-employees-performance?pg=1&lm=10",
      requestOptions
    )

      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        
        setTableData(result?.data)
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);
  

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(TableData?.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };

  const getData = (current, pageSize) => {
    // Normally you should get the data from the server
    return TableData?.slice((current - 1) * pageSize, current * pageSize);
  };

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <i className="fa fa-angle-double-left"></i>
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <i className="fa fa-angle-double-right"></i>
        </button>
      );
    }
    return originalElement;
  };




  // End Api Call in Get

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content sn-employees-managment">
        <Container fluid>
          <BreadCrumb title="Agent Performance Report" pageTitle="" />
          <Row>
            <Col lg={12}>
              {/* Table Start */}
              <Card className="h-100">
                <hr />
                <div className="table-responsive">
                  <Table>
                    <thead className="table-text">
                      <tr className="align-middle">
                        {Tablethead?.map((item, i) => (
                          <th scope="col" key={i}>
                            {item.title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {getData(current, size)?.map((data, index) => (
                        <>
                        <tr key={index} className="align-middle">
                          <th className="p-4" scope="row">
                          {(current - 1) * size + (index + 1)}
                          </th>
                          <td className="p-4 table-name">{data?.fullName} </td>
                          <td className="p-4">{data?.uniqueClient}</td>
                          <td className="p-4">{data?.totalDuration}</td>
                          <td className="p-4">{data?.avrerageCallDuration}</td>
                          <td className="p-4">
                            <span
                              className="px-3 rounded-pill"
                              style={{ backgroundColor: "rgb(212, 244, 255)" }}
                            >
                              {data?.totalCall}
                            </span>
                          </td>
                          <td className="p-4">
                            <span
                              className="px-3 rounded-pill"
                              style={{ backgroundColor: "rgb(227, 255, 255)" }}
                            >
                              {data?.totalIncomingCall}
                            </span>
                          </td>
                          <td className="p-4">
                            <span
                              className="px-3 rounded-pill"
                              style={{ backgroundColor: "rgb(223, 255, 223)" }}
                            >
                              {data?.totalOutgoingCall}
                            </span>
                          </td>
                          <td className="p-4">
                            <span
                              className="px-3 rounded-pill"
                              style={{ backgroundColor: "rgb(255, 218, 224)" }}
                            >
                              {data?.totalMissedCall}
                            </span>
                          </td>
                          <td className="p-4">
                            <span
                              className="px-3 rounded-pill"
                              style={{ backgroundColor: "rgb(255, 237, 216)" }}
                            >
                              {data?.totalRejectedCall}
                            </span>
                          </td>
                          <td className="p-4">
                            <span
                              className="px-3 rounded-pill"
                              style={{ backgroundColor: "rgb(255, 229, 255)" }}
                            >
                              {data?.totalNotPickedUpByClient}
                            </span>
                          </td>
                        </tr>
                        </>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <div className="table-filter-info">
                  <Pagination
                    className="pagination-data"
                    showTotal={(total, range) =>
                      `Showing ${range[0]}-${range[1]} of ${total}`
                    }
                    onChange={PaginationChange}
                    total={TableData?.length}
                    current={current}
                    pageSize={size}
                    showSizeChanger={false}
                    itemRender={PrevNextArrow}
                    onShowSizeChange={PerPageChange}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AgentTable;
